import { ReactElement } from 'react';
import { DOC_TYPE, PRODUCT_TYPE } from '../Constants/Constant';
import { CallBackPayloadType } from './Interfaces';
import { ReceiveGoodsItemsPayload } from './ReceivedGoodsItem';

export interface ReceivedGoodsPopupState {
  documentDetails: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  documentType: DOC_TYPE;
  closePopup?: () => void;
}

export interface ReceivedGoodsRecordProps {
  documentDetails: any;
  receivedGoodsDetails: any;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  documentType: DOC_TYPE;
  isDeleted: (val: boolean) => void;
  className?: string;
  selectedTabIndex?: number;
  isFromMaster?: boolean;
  isToleranceApprovalFlow?: boolean;
}

export interface ReceivedGoodState {
  id: any;
  documentCode: string;
  documentType: string;
  items: ReceivedGoodItemState[];
  receiptDate: string;
  warehouseCode: string;
  customField: any;
}

export interface ReceivedGoodItemState {
  id?: string;
  documentItemCode: string;
  receiptQuantity: number;
  productCode: string;
  product: any;
  productId: string;
  productDescription: string;
  productName: string;
  quantityRequired: number;
  pendingQuantity: number;
  receiptDate: string;
  purchaseOrderItemCode: string;
  warehouseCode: string;
  availableQuantity: number;
  advancedTracking: string;
  advancedTrackingType: string;
  advancedTrackingMetaData: Array<any>;
  documentUom: number;
  documentUOMSchemaDefinition: any;
  uomReceiptQuantity: number;
  uomQuantityRequired: number;
  lineNumber: number;
  uomQuantity: number;
  productQuantity: number;
  receivedQuantityInBills: number;
  receivedQuantityInOrder: number;
  type: PRODUCT_TYPE;
  uomReceivedQuantityInOrder: any;
  uomReceivedQuantityInBills: any;
  uomAvailableQuantity: any;
  totalFGDispatchedQty?: any;
}

export class ReceiveGoodsPayload {
  documentCode: string;
  documentType: string;
  items: ReceiveGoodsItemsPayload[];
  receiptDate: string;
  warehouseCode: string;
  customField: any;
  attachments: any;
  attachmentIds: any;
  isQcEnabled: boolean;
  shipFrom: any;
  shipTo: any;

  constructor(props: any) {
    this.documentCode = props.documentCode;
    this.documentType = props.documentType;
    this.receiptDate = props.receiptDate;
    this.customField = props.customField;
    this.warehouseCode = props.warehouseCode;
    this.attachmentIds = props.attachmentIds;
    this.isQcEnabled = props.isQcEnabled;
    this.attachments = props.attachments;
    this.shipFrom = props?.shipFrom;
    this.shipTo = props?.shipTo;
    if (props.items) {
      this.items = props.items.map((item: any) => {
        return new ReceiveGoodsItemsPayload({
          ...item,
          warehouseCode: item.warehouseCode || props.warehouseCode
        });
      });
    } else {
      this.items = [];
    }
  }
}
