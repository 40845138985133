import React, { useEffect, useRef, useState } from 'react';
import { removeLoader, showAlert, showLoader } from 'deskera-ui-library';
import { Invoice, InvoiceInitialState } from '../../Models/Invoice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';
import Utility, { deepClone, getCapitalized } from '../../Utility/Utility';
import InvoiceService from '../../Services/Invoice';
import {
  deleteDrafts,
  draftTableId,
  draftTypeColumnId,
  fetchDrafts,
  isSaveColumnId,
  removeDraft,
  selectDraftsColumnConfig,
  setDraftActionAvailibility,
  setDraftIsErrorOnSave,
  setDraftValidationDisplayStatus,
  updatePopulateFormData
} from '../../Redux/Slices/DraftsSlice';
import {
  APPROVAL_STATUS,
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOC_PATH_WITH_ID_REGEX,
  DOC_TYPE,
  DOCUMENT_MODE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  LABELS,
  PAYMENT_STATUS,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  PRODUCT_TYPE,
  REGEX,
  STATUS_TYPE,
  TRACKING_TYPE
} from '../../Constants/Constant';
import NewDocument2 from '../../SharedComponents/DocumentForm/NewDocument2';
import { Document } from '../../Models/Document';
import { setItemsFromSalesInvoiceItems } from './InvoiceHelper';
import { fetchQuotes } from '../../Redux/Slices/QuotesSlice';
import {
  cascadingDiscountsInvalidMessage,
  checkCreditLimit,
  checkGSTINPresentForSelectedContact,
  checkIfTotalDiscountInvalid,
  customFieldsContainsErrors,
  getDocumentAlert,
  handleReservedQuantityDataObject,
  inactiveContactMessage,
  isDocContactInactive,
  rebuildCascadingDiscountsForSaving,
  removeUnwantedPayloadKeysForDocument,
  showAlertOnDocAPIError,
  updateAddressAsPerLocationCF
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import { fetchapprovalConditionList } from '../../Redux/Slices/AutomationSlice';
import AuthService from '../../Services/Auth';
import NumberFormatService from '../../Services/NumberFormat';
import { DraftTypes } from '../../Models/Drafts';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import {
  CREDIT_LIMIT_TYPE,
  DOC_URL_ACTION,
  WALKTHROUGH_EVENT
} from '../../Constants/Enum';
import {
  activeTenantInfo,
  tenantSetupStauts,
  userInfo
} from '../../Redux/Slices/AuthSlice';
import Peppol from '../../SharedComponents/Peppol';
import DateFormatService from '../../Services/DateFormat';
import DraftService from '../../Services/Drafts';
import { fetchSalesOrders } from '../../Redux/Slices/SalesOrderSlice';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import { useHistory } from 'react-router-dom';
import { localizedText } from '../../Services/Localization/Localization';
import { selectedAccounts } from '../../Redux/Slices/AccountsSlice';
import { PaymentItemDto } from '../../Models/PaymentPopup';
import PaymentService from '../../Services/Payment';
import { FulfillmentPayload } from '../../Models/Fulfillment';
import {
  fetchProductInventoryByID,
  selectWarehouseProductByID
} from '../../Redux/Slices/WarehouseProductSlice';
import QuotationService from '../../Services/Quotation';
import SalesOrderService from '../../Services/SalesOrder';
import FulfillmentService from '../../Services/FulfillmentService';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useTranslation } from 'react-i18next';
import PopupWrapper from '../../SharedComponents/PopupWrapper';
import Fulfillment from '../../SharedComponents/FulfillmentPopup/Fulfillment';
import ProductService from '../../Services/Product';
import {
  currentWalkthroughEvents,
  documentUpdated
} from '../../Redux/Slices/CommonDataSlice';
import RateAnalysisService from '../../Services/RateAnalysis';
import {
  getAgedReceivable,
  getPNL,
  getSellDashboard,
  selectAgedFilterConfig,
  selectPnlFilterConfig,
  selectSellFilterConfig
} from '../../Redux/Slices/DashboardSlice';
import DashboardService, { DashboardAPIConfig } from '../../Services/Dashboard';

export default function NewInvoice(props: any) {
  const [isCenterAlign, setIsCenterAlign] = useState<boolean>(
    !Utility.isEmpty(props.draftData)
      ? props.draftData.isCenterAlign
      : props.isCenterAlign
  );
  const [invoice, setInvoice] = useState<Invoice>(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromSalesInvoiceItems(
          props.populateFormData,
          props.documentMode
        )
      : InvoiceInitialState
  );

  const [updatedInvoice, setUpdatedInvoice] = useState<Invoice>();
  const [showPeppolDialog, setShowPeppolDialog] = useState(false);
  const [docForFulfillment, setDocForFulfillment] = useState<any>();
  const [showFulfillmentPopup, setShowFulfillmentPopup] =
    useState<boolean>(false);
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { updateInvoice: 'click' }
  };
  const addInvoiceRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const autoProcessDoc = useRef(false);
  const warehouseProductsByID = useAppSelector(selectWarehouseProductByID);
  const [quotationItems, setQuotationItems] = useState<any[]>([]);
  const [salesOrderItems, setSalesOrderItems] = useState<any[]>([]);

  const draftsTableId = useAppSelector(draftTableId);
  const isSavedColumnId = useAppSelector(isSaveColumnId);
  const draftTypeColId = useAppSelector(draftTypeColumnId);
  const dispatch = useAppDispatch();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const tenantSetupStatus = useAppSelector(tenantSetupStauts);
  const draftsColumnConfig = useAppSelector(selectDraftsColumnConfig);
  const accountList = useAppSelector(selectedAccounts);
  let history = useHistory();
  const userDetails = useAppSelector(userInfo);
  const walkthroughEvents = useAppSelector(currentWalkthroughEvents);
  const SellDashboardConfig = useAppSelector(selectSellFilterConfig);
  const AgedReceivableDashboardConfig = useAppSelector(selectAgedFilterConfig);
  const pnlDashboardConfig = useAppSelector(selectPnlFilterConfig);
  const { t, i18n } = useTranslation();
  const [zeroAmountDocument, setZeroAmountDocument] = useState(
    !Utility.isEmpty(props.populateFormData)
      ? setItemsFromSalesInvoiceItems(
          props.populateFormData,
          props?.documentMode
        ).totalAmount === 0
      : false
  );
  const fulfillmentPopupBtnConfig: BtnType[] = [
    {
      title: t(`INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: t(
        `INVOICES.DIALOG.DIRECT_FULFILLMENT_OF_INVOICES.BUTTON.FULLFILL`
      ),
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.FULFILLMENT
    }
  ];

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        if (showFulfillmentPopup) {
          setShowFulfillmentPopup(false);
          dispatch(removeDraft(props.draftData.id));
          dispatch(fetchInvoices());
        }
        break;
      case POPUP_CLICK_TYPE.FULFILLMENT:
        addInvoiceRef.current?.storeCallbacksRef.fulfillment();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.FULFILLMENT_SUCCESS:
        if (showFulfillmentPopup) {
          setShowFulfillmentPopup(false);
          updateForm(docForFulfillment);
          dispatch(removeDraft(props.draftData.id));
        }
        break;
      case POPUP_CALLBACKS_TYPE.FULFILLMENT:
        addInvoiceRef.current.storeCallbacksRef.fulfillment = passingData.data;
        break;
    }
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_INVOICE,
        data: async (closeDoc: boolean, processDoc: boolean) => {
          if (processDoc) {
            autoProcessDoc.current = true;
          }
          await createInvoice(closeDoc, processDoc);
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_DRAFT_POPUP,
        data: () => {
          closeDraftPopup();
        }
      });

    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.SAVE_AS_DRAFT,
        data: () => {
          updatedInvoice?.id ? updateInvoice() : onSaveAsDraft();
        }
      });

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.UPDATE_INVOICE,
        data: () => {
          updateInvoice();
        }
      });
    }
  };

  useEffect(() => {
    if (!Utility.isEmpty(props.populateFormData)) {
      setInvoice({ ...props.populateFormData });
    }
  }, [props.populateFormData]);

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    setIsCenterAlign(
      !Utility.isEmpty(props.draftData)
        ? props.draftData.isCenterAlign
        : props.isCenterAlign
    );

    if (!Utility.isEmpty(props.populateFormData)) {
      setInvoice({
        ...invoice,
        documentDate: props.populateFormData.salesInvoiceDate,
        validTillDate: props.populateFormData.salesInvoiceDueDate,
        fulfillmentDate: props.populateFormData.shipByDate
      });
    }
    loadApprovalConditionList();

    if (
      tenantInfo.country === COUNTRY_CODES.SG &&
      !tenantInfo?.peppolOptIn &&
      !tenantSetupStatus?.onboardingStatusInfo?.sgEInvoiceChecked
    ) {
      setShowPeppolDialog(true);
    }

    return () => {
      // Handle URL for quote to invoice conversion initiated from CRM
      if (
        history.location?.pathname.includes(DOC_URL_ACTION.CONVERT_TO_INVOICE)
      ) {
        history.replace(PAGE_ROUTES.QUOTES);
      }
      console.log('cleanup..');
    };
  }, []);

  useEffect(() => {
    if (!Utility.isEmpty(walkthroughEvents)) {
      if (walkthroughEvents.includes(WALKTHROUGH_EVENT.OPEN_DOC_CONTACT_LIST)) {
        // Hide peppol dialog for walk through
        setShowPeppolDialog(false);
      }
      if (walkthroughEvents.includes(WALKTHROUGH_EVENT.CLOSE_DOC_FORM)) {
        dispatch(removeDraft(props.draftData.id));
      }
    }
  }, [walkthroughEvents]);

  useEffect(() => {
    setIsCenterAlign(
      !Utility.isEmpty(props.draftData)
        ? props.draftData.isCenterAlign
        : props.isCenterAlign
    );
  }, [props]);

  // Effect to populate warehouses with inventory details
  useEffect(() => {
    if (
      !Utility.isEmpty(docForFulfillment) &&
      !Utility.isEmpty(warehouseProductsByID) &&
      !showFulfillmentPopup
    ) {
      const defaultWH =
        warehouseProductsByID.find((warehouse: any) => warehouse.primary) ||
        warehouseProductsByID[0];

      if (defaultWH?.code) {
        updateInventoryAndFulfill(defaultWH);
      }
    }
  }, [warehouseProductsByID]);

  const loadApprovalConditionList = () => {
    dispatch(fetchapprovalConditionList());
  };

  const onSaveAsDraft = () => {
    let payload = { ...updatedInvoice };

    payload['approvalStatus'] = payload['approvalStatus']
      ? payload['approvalStatus']
      : APPROVAL_STATUS['NOT_REQUIRED'];
    payload['createdUserName'] = AuthService.getUserName();
    payload['dueAmount'] = 0;
    setButtonStatus(true);

    // here we explicitly remove few keys, which is not needed to be saved in component list feature
    payload = removeUnwantedPayloadKeysForDocument(payload);

    props.formData(payload);
    replaceURLToModuleURL();
  };

  // Change URL to base module URL
  const replaceURLToModuleURL = () => {
    if (
      DOC_PATH_WITH_ID_REGEX.test(history.location?.pathname) &&
      history.location?.pathname?.includes(PAGE_ROUTES.INVOICES)
    ) {
      history.replace(PAGE_ROUTES.INVOICES);
    }
  };

  const closeDraftPopup = () => {
    dispatch(removeDraft(props.draftData.id));
    replaceURLToModuleURL();
  };

  const isDocValid = (docToValidate: any, isDraft?: boolean) => {
    // Validate Manual Document Sequence Code
    if (
      Utility.isEmpty(docToValidate.documentSequenceCode) &&
      Utility.isEmpty(docToValidate.sequenceFormat) &&
      docToValidate.manualMode
    ) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.contact)) {
      return false;
    }

    if (Utility.isEmpty(docToValidate.salesInvoiceItems)) {
      return false;
    }

    if (!Utility.isEmpty(docToValidate.documentDate)) {
      const docDate = DateFormatService.getDateFromStr(
        docToValidate.documentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      if (
        !Utility.checkActiveDateRangeValidation(
          docDate,
          tenantInfo,
          'Invoice date',
          docToValidate.documentType
        )
      ) {
        return false;
      }
      if (!Utility.checkClosingDate(docDate, 'Invoice date')) {
        return false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(
      docToValidate.customField
    );
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    // Line item errors
    let lineItemsHasErrors = false;
    for (let i = 0; i < docToValidate.salesInvoiceItems.length; i++) {
      const item = docToValidate.salesInvoiceItems[i];
      if (item.hasError || item.invalidFields?.length) {
        lineItemsHasErrors = true;
        break;
      }
    }

    if (lineItemsHasErrors) {
      return false;
    }
    // Line item errors ends

    // Contact GSTIN check
    if (!checkGSTINPresentForSelectedContact(docToValidate)) {
      return false;
    }
    // Contact GSTIN check ends

    // Check for -ve total amount
    const totalBeforeTax = docToValidate?.salesInvoiceItems?.length
      ? docToValidate?.salesInvoiceItems?.reduce((total: number, item: any) => {
          return total + item?.totalWithDiscount;
        }, 0)
      : 0;
    if (totalBeforeTax < 0) {
      showAlert('Invalid amount!', 'Invoice amount can not be less than 0.');
      return false;
    }
    // Check for -ve total amount ends

    // amountToReceiveOrPay check
    let paidAmount =
      docToValidate.knockoffInfo?.reduce(
        (total: number, paymentInfo: any) =>
          total +
          Number(paymentInfo?.amount || 0) *
            Number(paymentInfo?.exchangeRate || 1),
        0
      ) || 0;

    if (
      autoProcessDoc.current &&
      !REGEX.DECIMAL_NUMBER.test(docToValidate.amountToReceiveOrPay)
    ) {
      showAlert('Error!', `<b>Amt. to Receive</b> is not valid.`);
      return false;
    }
    // amountToReceiveOrPay ends

    // Cascading discounts validation
    const cascadingDiscountSettings =
      tenantInfo.additionalSettings?.CASCADING_DISCOUNTS;
    if (cascadingDiscountSettings?.enable) {
      const isTotalDiscountInvalid = checkIfTotalDiscountInvalid(
        docToValidate,
        'salesInvoiceItems'
      );
      if (isTotalDiscountInvalid) {
        showAlert('Error!', cascadingDiscountsInvalidMessage);
        return false;
      }
    }
    // Cascading discounts validation ends

    // Additional charges check
    let additionalChargesHasErrors = false;
    const additionalCharges =
      docToValidate.additionalCharges?.additionalChargesDetails;
    if (!Utility.isEmpty(additionalCharges)) {
      for (let i = 0; i < additionalCharges.length; i++) {
        const item = additionalCharges[i];
        if (item.hasError) {
          additionalChargesHasErrors = true;
          break;
        }
      }
    }
    if (additionalChargesHasErrors) {
      return false;
    }
    // Additional charges check ends

    // Discount check
    const discountHasErrors =
      docToValidate.additionalCharges?.globalDiscount?.hasError;
    if (discountHasErrors) {
      return false;
    }
    // Discount check ends

    // Validation for reserved stock
    if (
      docToValidate.reservedStock &&
      !docToValidate?.linkedWorkOrderDocuments?.length
    ) {
      const isStockInvalid = docToValidate.salesInvoiceItems.some(
        (obj: any) =>
          (obj.product?.type === PRODUCT_TYPE.TRACKED ||
            obj.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
          Utility.isEmpty(obj.reservedQuantitiesData)
      );
      if (isStockInvalid) {
        showAlert(
          'Error!',
          'Reserve Stock details are not added for one or more products'
        );
        return false;
      }
    }
    // Validation for reserved stock ends

    //validation product having create Revenue plan on REVENUE_ARRANGEMENT

    const revRecPlansWithoutDates = docToValidate.salesInvoiceItems.filter(
      (itemData: any) => {
        return (
          itemData?.product?.revenueRecognitionInfo?.isDirectRevenuePositing ===
            false &&
          itemData?.product?.revenueRecognitionInfo?.allocationType !==
            'EXCLUDE' &&
          itemData?.product?.revenueRecognitionInfo?.createRevenuePlanOn ===
            'REVENUE_ARRANGEMENT' &&
          (itemData?.revRecEndDate === null ||
            itemData?.revRecStartDate === null)
        );
      }
    );
    if (!Utility.isEmpty(revRecPlansWithoutDates)) {
      showAlert('Error!', 'Rev Rec Start Date or End Date should not be empty');
      return false;
    }
    return true;
  };

  const setButtonStatus = (status: boolean) => {
    dispatch(
      setDraftActionAvailibility({ id: props.draftData.id, status: status })
    );
  };

  const setIsErrorOnSaveButton = (status: boolean) => {
    dispatch(setDraftIsErrorOnSave({ id: props.draftData.id, status: status }));
  };

  const setValidationDisplayStatus = (status: boolean) => {
    dispatch(
      setDraftValidationDisplayStatus({
        id: props.draftData.id,
        status: status
      })
    );
  };

  const showCreditLimitAlert = (
    creditLimitSettings: any,
    payload: any,
    isUpdate = false,
    closeOnUpdate = false,
    closeOnCreate = false,
    processDoc = false
  ) => {
    let buttons = [];

    buttons.push({
      title:
        creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN
          ? 'Cancel'
          : 'Ok',
      className: 'border-m',
      onClick: () => {
        setButtonStatus(false);
      }
    });

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      buttons.push({
        title: 'Save',
        className: 'bg-blue text-white ml-r',
        onClick: () => {
          if (isUpdate) {
            makeUpdateAPICall(payload, closeOnUpdate);
          } else {
            makeCreateAPICall(payload, closeOnCreate, processDoc);
          }
        }
      });
    }

    const baseCurrencyCode = tenantInfo.currency;
    const currencySymbol = Utility.getCurrencySymbolFromCode(baseCurrencyCode);

    let message = '';
    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.WARN) {
      message = `Credit Limit for ${payload?.contact?.name} has reached. Do you wish to proceed?`;
    }

    if (creditLimitSettings.creditLimitType === CREDIT_LIMIT_TYPE.BLOCK) {
      message += `Credit Limit for ${payload?.contact?.name} has reached. You cannot proceed.<br/><br/>`;
      message += '<ul>';
      message += `<li>Credit Limit: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.creditLimitInBaseCurrency
      )}</li>`;
      message += `<li>Amount Due: ${currencySymbol}${NumberFormatService.getNumber(
        creditLimitSettings.totalDueAmount
      )}</li>`;
      message += creditLimitSettings.includeCurrentDoc
        ? '<li class="text-gray" style="font-size: 12px;">(Incl. current invoice)</li>'
        : '';
      message += '</ul>';
    }

    showAlert('Credit Limit Reached', message, buttons);
  };

  // Create Invoice
  const createInvoice = async (closeOnCreate: boolean, processDoc: boolean) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedInvoice);
    payload = updateAddressAsPerLocationCF(payload);
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        salesInvoiceItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }
    delete payload?.items;
    delete payload['sourceDocTypeForConversion'];

    if (
      (props.documentMode === DOCUMENT_MODE.COPY ||
        props.documentMode === DOCUMENT_MODE.NEW) &&
      (payload?.duplicate || payload?.isConverting)
    ) {
      const isContactInactive = isDocContactInactive(payload?.contact);
      if (isContactInactive) {
        showAlert('Error', inactiveContactMessage);
        setButtonStatus(false);
        return;
      }
    }

    if (payload.reservedStock) {
      const isTrackedOrBOM = payload.salesInvoiceItems.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTrackedOrBOM) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(
          payload,
          'salesInvoiceItems'
        );
      }
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    if (props.draftData?.draftType === DraftTypes.DRAFT) {
      payload[
        'draftReferenceId'
      ] = `${props.draftData?.data?.draftsTableId}/record/${props.draftData?.data?.id}`;
      payload['draftSeqCode'] = props.draftData?.data?.draftCode;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        false,
        true,
        closeOnCreate,
        processDoc
      );
    } else {
      makeCreateAPICall(payload, closeOnCreate, processDoc);
    }
  };

  const paymentAlertBtns = (formData: any) => {
    return [
      {
        title: 'Cancel',
        className: 'border-m mr-r',
        onClick: () => {
          dispatch(removeDraft(props.draftData.id));
        }
      },
      {
        title: 'Continue',
        className: 'bg-button text-white',
        onClick: () => {
          initiateFulfillment(formData);
        }
      }
    ];
  };

  // Auto receive payment for cash invoice
  const receivePayment = (formData: any) => {
    showLoader('Receiving payment...');
    let tdsAccountCode = '';
    if (!Utility.isEmpty(formData.accountGroupForPayment)) {
      // TDS account details
      if (
        accountList &&
        accountList.content &&
        accountList.content.length > 0
      ) {
        const tdsAccount = accountList.content?.find(
          (acc: any) =>
            acc.status === STATUS_TYPE.ACTIVE && acc.name === 'TDS Payable'
        );
        if (!Utility.isEmpty(tdsAccount)) {
          tdsAccountCode = tdsAccount.code;
        }
      }

      const receivePaymentItem: PaymentItemDto = {
        customerOrderNumber: '',
        documentCode: formData.salesInvoiceCode,
        documentType: formData.documentType,
        exchangeRate: 1,
        paymentAmount: Utility.roundOffToTenantDecimalScale(
          +formData.amountToReceiveOrPay
        ),
        documentSequenceCode: formData.documentSequenceCode
      };

      const formattedDate = DateFormatService.getFormattedDateString(
        formData.salesInvoiceDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY'],
        BOOKS_DATE_FORMAT['YYYY-MM-DD']
      );

      let paymentPayload: any = {
        accountCodePayTo: formData?.accountGroupForPayment?.value,
        accountName: formData?.accountGroupForPayment?.label,
        amount: Utility.roundOffToTenantDecimalScale(
          +formData.amountToReceiveOrPay
        ),
        closeDate: formattedDate,
        contactCode: formData.contactCode,
        contactName: formData.contact ? formData.contact.name : '',
        currency: formData.currency,
        documentDate: formattedDate,
        exchangeRate: 1 / formData.exchangeRate,
        isUnDepositedPayment:
          formData?.accountGroupForPayment?.isUndepositedFundAccount,
        paymentType:
          formData?.accountGroupForPayment?.label === 'Cash'
            ? 'CASH'
            : formData.paymentType,
        receivePaymentItemDtoList: [receivePaymentItem],
        referenceDate: formattedDate,
        referenceNumber: '',
        tdsAccountCode: tdsAccountCode,
        tdsAmount: 0,
        whtApplicable: false,
        whtRate: 0
      };

      PaymentService.receivePayment(paymentPayload).then(
        (res: any) => {
          // Initiate fulfillment
          initiateFulfillment(formData);
        },
        (err: any) => {
          console.error('Error receiving payment: ', err);
          removeLoader();
          showAlert(
            'Error!',
            `Error while receiving payment. Do you want to continue with creating ${localizedText(
              'Fulfillment'
            )}?`,
            paymentAlertBtns(formData)
          );
        }
      );
    } else {
      removeLoader();
      showAlert(
        'No account selected!',
        `No account has been selected for receiving payment. Do you want to continue with creating ${localizedText(
          'Fulfillment'
        )}?`,
        paymentAlertBtns(formData)
      );
    }
  };

  // Initiate auto fulfillment flow for cash invoice
  const initiateFulfillment = (formData: any) => {
    formData = {
      ...formData,
      documentCode: formData.salesInvoiceCode
    };
    delete formData.sequenceFormat;

    setDocForFulfillment(formData);

    showLoader(`Creating ${localizedText('Fulfillment')}...`);
    if (formData.fulfillmentStatus !== FULFILLMENT_STATUS.FULLY_FULFILLED) {
      checkLinkedDocsAndLoadInventoryDetails(formData);
    } else {
      dispatch(removeDraft(props.draftData.id));
      removeLoader();
      dispatch(fetchInvoices());
    }
  };

  const updateForm = (
    invoiceResp: any,
    isUpdate = false,
    closeOnUpdate = true,
    processDoc = false
  ) => {
    InvoiceService.getInvoiceByCode(invoiceResp.salesInvoiceCode).then(
      (doc: any) => {
        const formData = {
          ...doc,
          documentType: DOC_TYPE.INVOICE,
          items: [...doc.salesInvoiceItems],
          documentDate: doc.saleInvoiceDate,
          fulfillmentDate: doc.shipByDate,
          validTillDate: doc.salesInvoiceDueDate,
          amountToReceiveOrPay: updatedInvoice?.amountToReceiveOrPay,
          paymentType: updatedInvoice?.paymentType,
          accountGroupForPayment: updatedInvoice?.accountGroupForPayment
        };
        if (!closeOnUpdate) {
          dispatch(
            updatePopulateFormData({
              id: props.draftData?.id,
              formdata: formData,
              draftType: DraftTypes.UPDATE,
              actionFromDocument: true
            })
          );
        } else {
          if (!isUpdate && !processDoc) {
            dispatch(removeDraft(props.draftData.id));
            getDocumentAlert(
              'Invoice created!',
              'Document has been created successfully.',
              formData,
              props.draftData,
              DOCUMENT_MODE.EDIT,
              PAGE_ROUTES.INVOICES
            );
            setButtonStatus(false);
          }
          if (processDoc) {
            if (doc.paymentStatus !== PAYMENT_STATUS.RECEIVED) {
              // Initiate receive paymant flow
              receivePayment(formData);
            } else {
              initiateFulfillment(formData);
            }
          }
        }
      },
      (err) => {
        console.error('Error loading updated doc: ', err);
        setButtonStatus(false);
      }
    );
  };

  const fulfillmentAlertBtns = [
    {
      title: 'Cancel',
      className: 'border-m mr-r',
      onClick: () => {
        dispatch(removeDraft(props.draftData.id));
        dispatch(fetchInvoices());
        updateForm(docForFulfillment);
      }
    },
    {
      title: `Create ${localizedText('Fulfillment')}`,
      className: 'bg-button text-white',
      onClick: () => {
        setShowFulfillmentPopup(true);
      }
    }
  ];

  // Get linked documents info from Invoice and continue auto fulfillment flow
  const checkLinkedDocsAndLoadInventoryDetails = (doc: any) => {
    let ids = doc.salesInvoiceItems?.map((item: any) => item.productCode);

    const partialInvoiceFlag =
      doc.isPartialInvoice || doc.hasPartialInvoice || false;
    if (partialInvoiceFlag) {
      if (doc?.linkedDocuments?.[0]?.documentType === DOC_TYPE.QUOTE) {
        const quotationDocumentCode = doc.linkedDocuments[0].documentCode;

        QuotationService.getQuoteByCode(quotationDocumentCode).then(
          (data: any) => {
            setQuotationItems(data['quotationItemDtoList']);
            loadProductInventoryById(ids);
          },
          (err: any) => {
            removeLoader();
            showAlert(
              'Error!',
              `Error while loading quote details. Please try again.`,
              fulfillmentAlertBtns
            );
            console.error('Error loading linked quote details: ', err);
          }
        );
      } else if (
        doc?.linkedDocuments?.[0]?.documentType === DOC_TYPE.SALES_ORDER
      ) {
        const salesOrderDocumentCode = doc.linkedDocuments[0].documentCode;

        SalesOrderService.getSalesOrderByCode(salesOrderDocumentCode).then(
          (data: any) => {
            setSalesOrderItems(data['salesOrderItems']);
            loadProductInventoryById(ids);
          },
          (err: any) => {
            removeLoader();
            showAlert(
              'Error!',
              `Error while loading linked sales order details. Please try again.`,
              fulfillmentAlertBtns
            );
            console.error('Error loading linked SO details: ', err);
          }
        );
      }
    } else {
      loadProductInventoryById(ids);
    }
  };

  // Load Product inventory details for auto fulfillment of cash invoice
  const loadProductInventoryById = async (ids: any) => {
    try {
      await dispatch(fetchProductInventoryByID(ids));
    } catch (err) {
      removeLoader();
      showAlert(
        'Error!',
        'Error loading inventory information for products',
        fulfillmentAlertBtns
      );
      console.error('Error fetching ProductInventoryByID: ', err);
    }
  };

  // Get default product warehouse
  const getWarehouseForItem = (data: any) => {
    const warehouses = warehouseProductsByID;
    if (!Utility.isEmpty(warehouses)) {
      let itemWarehouse = warehouses.filter(
        (warehouse: any) =>
          warehouse['productAvailableQuantity']?.[data.productCode]
      );
      let primaryWH: any = [];
      if (!itemWarehouse || itemWarehouse.length > 1) {
        primaryWH = warehouses.filter((warehouse: any) => warehouse.primary);
      } else {
        primaryWH = itemWarehouse;
      }
      if (primaryWH.length === 0) {
        primaryWH[0] = warehouses[0];
      }
      return primaryWH[0].code;
    } else {
      const defaultWH =
        warehouseProductsByID.find((warehouse: any) => warehouse.primary) ||
        warehouseProductsByID[0];
      return defaultWH.code;
    }
  };

  // Create fulfillment items from document items and inventory details
  const updateInventoryAndFulfill = (defaultWH: any) => {
    let filteredProduct = warehouseProductsByID.find(
      (warehouse: any) => warehouse.code === defaultWH.code
    );
    let backOrderItem: any = {};
    let newFulfillmentItems: any[] = [];
    let isBOMItemInsufficient = false;
    let fulfillmentItems = deepClone(docForFulfillment?.salesInvoiceItems);
    fulfillmentItems.forEach((item: any, index: number) => {
      const pendingQuantity = Utility.getPendingQuantity(item);
      if (item.type === PRODUCT_TYPE.NON_TRACKED) {
        item.availableProductQuantity = 0;
      } else {
        item.availableProductQuantity =
          (!Utility.isEmpty(filteredProduct?.productAvailableQuantity) &&
            filteredProduct?.productAvailableQuantity[item.productCode]) ||
          item.product.availableQuantity ||
          0;
      }

      if (
        docForFulfillment &&
        (docForFulfillment.isPartialInvoice ||
          docForFulfillment.hasPartialInvoice)
      ) {
        item.quantityToBeFulfilled =
          item.productQuantity - (item.quantityFulfilled || 0);
        item.uomQuantityToBeFulfilled =
          item.uomPendingQuantity - (item.uomQuantityFulfilled || 0);
      }
      item.fulfilledQuantity =
        pendingQuantity > item.availableProductQuantity &&
        item.type !== PRODUCT_TYPE.NON_TRACKED
          ? item.availableProductQuantity
          : pendingQuantity;
      item.pendingQuantity = pendingQuantity;

      if (item.documentUOMSchemaDefinition) {
        item.uomQuantity =
          item.uomQuantity ||
          Utility.getUomQuantity(
            item.productQuantity,
            item.documentUOMSchemaDefinition
          );
        item.uomUnitPrice =
          item.uomUnitPrice ||
          Utility.getUomPrice(item.unitPrice, item.documentUOMSchemaDefinition);
        item.uomAvailableQuantity =
          item.uomAvailableQuantity ||
          Utility.getUomQuantity(
            item.availableQuantity,
            item.documentUOMSchemaDefinition
          );
        item.uomFulfilledQuantity =
          item.uomFulfilledQuantity ||
          Utility.getUomQuantity(
            item.fulfilledQuantity,
            item.documentUOMSchemaDefinition
          );
        item.uomQuantityFulfilled =
          item.uomQuantityFulfilled ||
          Utility.getUomQuantity(
            item.quantityFulfilled,
            item.documentUOMSchemaDefinition
          );
        item.uomPendingQuantity =
          item.uomPendingQuantity ||
          Utility.getUomQuantity(
            item.pendingQuantity,
            item.documentUOMSchemaDefinition
          );
      }
      item.warehouseCode = getWarehouseForItem(item);

      if (
        (item.type === PRODUCT_TYPE.TRACKED ||
          item.type === PRODUCT_TYPE.BILL_OF_MATERIALS) &&
        item.availableQuantity < pendingQuantity
      ) {
        backOrderItem[item.productCode] =
          pendingQuantity - item.availableQuantity;
        if (item.type === PRODUCT_TYPE.BILL_OF_MATERIALS) {
          isBOMItemInsufficient = true;
        }
      }
      newFulfillmentItems.push(item);
    });
    if (
      (!tenantInfo.allowNegativeInventory || isBOMItemInsufficient) &&
      Object.keys(backOrderItem).length > 0
    ) {
      removeLoader();
      showAlert(
        'Insufficient quantity!',
        'One or more products have insufficient quantity. Please try again.',
        fulfillmentAlertBtns
      );
      return;
    }
    saveFulfillment(newFulfillmentItems, defaultWH);
  };

  // Update Serial Tracked Item
  const updateSerialTrackedItem = async (item: any) => {
    let quantityToFulfill = 0;
    let requiredQuantity = 0;

    const availableQuantity = Utility.getAvailableQuantity(item);

    try {
      let wareHouseBatchProduct =
        await ProductService.fetchProductAdvancedTrackingWarehouse(
          item?.productCode,
          false,
          false
        );
      if (Utility.isEmpty(wareHouseBatchProduct)) {
        item.errorMessage =
          'One or more serial tracked products has insufficient stock.';
      }

      if (item.pendingQuantity > availableQuantity) {
        item.errorMessage =
          'One or more serial tracked products has insufficient stock.';
        requiredQuantity = availableQuantity;
      } else {
        requiredQuantity = item.pendingQuantity;
      }

      if (!Number.isInteger(item.productQuantity)) {
        item.errorMessage =
          'One or more serial tracked products have required quantity in decimal.';
      }

      if (item?.documentUOMSchemaDefinition) {
        quantityToFulfill = item?.productQuantity;
      } else {
        quantityToFulfill = requiredQuantity;
      }

      const fulfilledQuantity = item.documentUOMSchemaDefinition
        ? Utility.getUomQuantity(
            parseFloat(quantityToFulfill.toString()),
            item.documentUOMSchemaDefinition
          )
        : quantityToFulfill;
      item = {
        ...item,
        advancedTracking: TRACKING_TYPE.SERIAL,
        isQuickCommit: true,
        quantityFulfilled: quantityToFulfill,
        fulfilledQuantity: quantityToFulfill,
        uomFulfilledQuantity: item.documentUOMSchemaDefinition
          ? fulfilledQuantity
          : item.uomFulfilledQuantity
      };
    } catch (err: any) {
      console.error('Error while fethcing advanced tracking details: ', err);
      item.errorMessage = `Error while fetching advanced tracking details of Product: ${item.name}`;
    }

    return item;
  };

  const updateBatchTrackedItem = async (batchTrackedItem: any) => {
    let requiredQuantity = 0;

    let availableQuantity = Utility.getAvailableQuantity(batchTrackedItem);

    if (batchTrackedItem.pendingQuantity > availableQuantity) {
      batchTrackedItem.errorMessage =
        'One or more batch tracked products has insufficient stock.';
      requiredQuantity = availableQuantity;
    } else {
      requiredQuantity = batchTrackedItem.pendingQuantity;
    }

    let wareHouseBatchProduct: any[] = [];
    try {
      wareHouseBatchProduct =
        await ProductService.fetchProductAdvancedTrackingWarehouse(
          batchTrackedItem?.productCode,
          false,
          false
        );
      if (Utility.isEmpty(wareHouseBatchProduct)) {
        // This will happen when the product has not tracking details assigned.
        batchTrackedItem.errorMessage =
          'One or more batch tracked products has insufficient stock.';
      }

      // Auto allocate
      let productAvailableQuantity = wareHouseBatchProduct.reduce(
        (prev: any[], current: any) => {
          let advTracking = current?.advancedTrackingMeta?.map((item: any) => {
            return {
              ...item,
              warehouseName: current.name
            };
          });
          return [...prev, ...advTracking];
        },
        []
      );
      let allocated: any = 0;
      let batchTrackingData: any = [];
      let totalFulfilledQty: any = 0;
      productAvailableQuantity?.every((element: any) => {
        let availableQtyToAssign = batchTrackedItem.documentUOMSchemaDefinition
          ? Utility.getUomQuantity(
              parseFloat(element.batchSize) -
                parseFloat(element.batchSizeFulfilled) -
                parseFloat(element.reservedQuantity),
              batchTrackedItem.documentUOMSchemaDefinition
            )
          : parseFloat(element.batchSize) -
            parseFloat(element.batchSizeFulfilled) -
            parseFloat(element.reservedQuantity);
        let lineItem = {
          qtyToFulfil: 0,
          serialBatchNumber: element?.serialBatchNumber,
          warehouseCode: element?.warehouseCode,
          warehouseName: element?.warehouseName
        };
        if (allocated < requiredQuantity) {
          if (availableQtyToAssign < requiredQuantity - allocated) {
            lineItem.qtyToFulfil = batchTrackedItem?.documentUOMSchemaDefinition
              ? Utility.getUomWarehouseQuantity(
                  availableQtyToAssign,
                  batchTrackedItem.documentUOMSchemaDefinition
                )
              : availableQtyToAssign;
            allocated = allocated + availableQtyToAssign;
            totalFulfilledQty += availableQtyToAssign;
          } else {
            lineItem.qtyToFulfil = batchTrackedItem?.documentUOMSchemaDefinition
              ? Utility.getUomWarehouseQuantity(
                  requiredQuantity - allocated,
                  batchTrackedItem.documentUOMSchemaDefinition
                )
              : requiredQuantity - allocated;
            totalFulfilledQty += requiredQuantity - allocated;
            batchTrackingData.push(lineItem);
            return false; //breaks the loop
          }
        }

        batchTrackingData.push(lineItem);
        return true;
      });

      const fulfilledQuantity = batchTrackedItem.documentUOMSchemaDefinition
        ? Utility.getUomWarehouseQuantity(
            parseFloat(totalFulfilledQty),
            batchTrackedItem.documentUOMSchemaDefinition
          )
        : totalFulfilledQty;

      // Check whether totalFulfilledQty < requiredQuantity
      // This will probably happen in case of reserved stocks.
      if (totalFulfilledQty < requiredQuantity) {
        batchTrackedItem.errorMessage =
          'One or more serial tracked products has insufficient stock.';
      }

      batchTrackedItem = {
        ...batchTrackedItem,
        advancedTracking: TRACKING_TYPE.BATCH,
        advancedTrackingFulfilmentData: batchTrackingData,
        isQuickCommit: false,
        quantityFulfilled: fulfilledQuantity,
        fulfilledQuantity: fulfilledQuantity
      };
      if (batchTrackedItem.documentUOMSchemaDefinition) {
        batchTrackedItem.uomFulfilledQuantity = totalFulfilledQty;
      }
    } catch (err: any) {
      console.error('Error: ', err);
      batchTrackedItem.errorMessage = `Error while fetching advanced tracking details of Product: ${batchTrackedItem.name}`;
    }

    return batchTrackedItem;
  };

  // Create fulfillment payload
  const fulfillmentPayload = async (
    fulfillmentItems: any[],
    defaultWH: any
  ) => {
    const isPartialInvoice =
      docForFulfillment?.isPartialInvoice ||
      docForFulfillment?.hasPartialInvoice ||
      false;

    fulfillmentItems = await Promise.all(
      fulfillmentItems.map(async (item: any) => {
        // Set fulfilledQuantity to pendingQuantity if negative inventory is ON
        if (tenantInfo.allowNegativeInventory) {
          if (item.documentUOMSchemaDefinition) {
            item.uomFulfilledQuantity = item.pendingQuantity;
          } else {
            item.fulfilledQuantity = item.pendingQuantity;
          }
        }
        if (item?.product?.advancedTracking === TRACKING_TYPE.SERIAL) {
          item = await updateSerialTrackedItem(item);
        }

        if (item?.product?.advancedTracking === TRACKING_TYPE.BATCH) {
          item = await updateBatchTrackedItem(item);
        }
        return item;
      })
    );

    const updatedFulfillmentItems: any[] = fulfillmentItems.filter(
      (item: any) =>
        (item.pendingQuantity > 0 && item.fulfilledQuantity > 0) ||
        !Utility.isEmpty(item.errorMessage)
    );

    let linkedDocType = docForFulfillment?.linkedDocuments?.[0]?.documentType;
    let docItems: any[] = [];
    switch (linkedDocType) {
      case DOC_TYPE.QUOTE:
        docItems = quotationItems;
        break;
      case DOC_TYPE.SALES_ORDER:
        docItems = salesOrderItems;
        break;
    }

    const fulfillmentPayload = {
      ...docForFulfillment,
      fulfillmentDate: DateFormatService.getDateStrFromDate(
        new Date(),
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      ),
      fulfillmentType: FULFILLMENT_TYPE.DEFAULT,
      warehouseCode: defaultWH.code,
      documentCode: docForFulfillment.salesInvoiceCode,
      documentType: DOC_TYPE.INVOICE,
      isPartialInvoice: isPartialInvoice,
      isPartialSalesOrder: false,
      reservedStock: docForFulfillment.reservedStock,
      linkedPIDocument:
        docForFulfillment.linkedDocuments &&
        docForFulfillment.fulfillmentType !== FULFILLMENT_TYPE.DROP_SHIP &&
        !docForFulfillment.backOrder
          ? docForFulfillment.linkedDocuments[0]
          : null,
      fulfillmentItems: updatedFulfillmentItems.map((item: any) => {
        const documentItem = docItems.find(
          (docItem) => docItem.productCode === item.productCode
        );

        return {
          ...item,
          advancedTracking: item.product.advancedTracking,
          uomQuantity: item.pendingQuantity,
          linkedPIItemCode:
            documentItem && linkedDocType === DOC_TYPE.QUOTE
              ? documentItem.quotationItemCode
              : documentItem && linkedDocType === DOC_TYPE.SALES_ORDER
              ? documentItem.salesOrderItemCode
              : null
        };
      })
    };

    // console.log('fulfillmentPayload: ', fulfillmentPayload);
    const payload = new FulfillmentPayload(fulfillmentPayload);
    return payload;
  };

  // Get payload and save fulfillment data
  const saveFulfillment = async (fulFillmentItems: any[], defaultWH: any) => {
    let payload = await fulfillmentPayload(fulFillmentItems, defaultWH);

    const itemsWithErrors = payload.fulfillmentItems?.filter(
      (item: any) => !Utility.isEmpty(item.errorMessage)
    );
    if (itemsWithErrors?.length) {
      removeLoader();
      showAlert(
        'Error!',
        itemsWithErrors[0]?.errorMessage,
        fulfillmentAlertBtns
      );
      return;
    }

    if (!tenantInfo.allowNegativeInventory) {
      let invalidItems: any = [];
      let invalidItemsProductCodes: any = [];
      let warehouseQtyList = deepClone(warehouseProductsByID);
      payload.fulfillmentItems?.forEach((record: any) => {
        if (
          record.warehouseInventoryData &&
          record.warehouseInventoryData?.length > 0
        ) {
          record.warehouseInventoryData.forEach((ele: any) => {
            warehouseQtyList.forEach((warehouse: any) => {
              if (
                warehouse.code === ele.warehouseCode &&
                warehouse?.productAvailableQuantity?.hasOwnProperty(
                  record.productCode
                )
              ) {
                if (
                  ele.quantity <=
                  warehouse.productAvailableQuantity[record.productCode]
                ) {
                  warehouse.productAvailableQuantity[record.productCode] =
                    warehouse.productAvailableQuantity[record.productCode] -
                    ele.quantity;
                } else {
                  if (!invalidItemsProductCodes.includes(record.productCode)) {
                    invalidItemsProductCodes.push(record.productCode);
                    invalidItems.push(record.productName);
                  }
                }
              }
            });
          });
        }
      });
      if (invalidItems?.length > 0) {
        removeLoader();
        showAlert(
          'Insufficient quantity!',
          invalidItems.toString() +
            ' have insufficient quantity. Please try again.',
          fulfillmentAlertBtns
        );
        return;
      }
    }

    FulfillmentService.saveFulfillment(payload)
      .then((res: any) => {
        dispatch(removeDraft(props.draftData.id));
        removeLoader();
        dispatch(fetchInvoices());
        updateForm(docForFulfillment);
      })
      .catch((err: any) => {
        console.error('Error creating fulfillment: ', err);
        dispatch(fetchInvoices());
        removeLoader();
        showAlert(
          'Error!',
          `Error while creating ${localizedText(
            'Fulfillment'
          )}. Please try again.`,
          fulfillmentAlertBtns
        );
      });
  };

  const makeCreateAPICall = (
    payload: any,
    closeDoc: boolean,
    processDoc: boolean
  ) => {
    payload = removeUnwantedPayloadKeysForDocument(payload);

    InvoiceService.createInvoice(payload).then(
      (response: any) => {
        if (props.draftData) {
          if (props.draftData.draftType === DraftTypes.DRAFT) {
            RateAnalysisService.updateRateAnalysis({
              documentCode: response.salesInvoiceCode,
              documentType: 'SALES_INVOICE',
              productPriceMethod:
                tenantInfo?.additionalSettings?.RATE_ANALYSIS
                  ?.PRODUCT_PRICE_METHOD,
              isDraft: props?.draftData.draftType == 'draft' ? true : false,
              draftId: props?.draftData.id,
              tableId: draftsTableId
            })
              .then(() => {})
              .catch((err: any) => {
                console.error('Error updating rate analysis: ', err);
              });
          }
          if (response.draft) {
            // Remove draft popup
            dispatch(removeDraft(props.draftData.id));
            setButtonStatus(false);

            const buttons = [
              {
                title: 'Goto Invoices',
                className: ' bg-blue text-white ml-r',
                onClick: () => {
                  RouteManager.navigateToPage(PAGE_ROUTES.INVOICES);
                }
              }
            ];
            showAlert(
              'Invoice sent for approval!',
              'Document has been created successfully.',
              buttons
            );
          } else {
            updateForm(response, false, true, processDoc);
          }
        } else {
          setButtonStatus(false);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.INVOICES
          })
        );
        dispatch(fetchInvoices());
        if (payload.isPartialInvoice) {
          if (payload.linkedDocuments?.[0]?.documentType === DOC_TYPE.QUOTE) {
            dispatch(fetchQuotes());
          }
          if (
            payload.linkedDocuments?.[0]?.documentType === DOC_TYPE.SALES_ORDER
          ) {
            dispatch(fetchSalesOrders());
          }
        }
        replaceURLToModuleURL();
        DashboardService.apiConfig = SellDashboardConfig.config;
        dispatch(getSellDashboard());
        DashboardService.apiConfig = AgedReceivableDashboardConfig.config;
        dispatch(getAgedReceivable());
        DashboardService.apiConfig = pnlDashboardConfig.config;
        dispatch(getPNL());
      },
      (err) => {
        console.error('Error while creating invoice: ', err);
        setButtonStatus(false);
        if (err?.data?.errorMessage) {
          let error = 'Fulfillment can not be done due to insufficient stock.';
          if (err?.data?.errorMessage === error) {
            var newError = deepClone(err);
            if (newError) {
              if (newError.data) {
                newError.data.errorMessage =
                  'Invoice is created, but fulfillment can not be done due to insufficient stock. Please go to invoice section to fulfil it.';
              }
            }
            showAlertOnDocAPIError(newError);
            setIsErrorOnSaveButton(true);
            dispatch(removeDraft(props.draftData.id));
          } else {
            showAlertOnDocAPIError(err);
            setIsErrorOnSaveButton(true);
            dispatch(removeDraft(props.draftData.id));
          }
        }
        replaceURLToModuleURL();
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.INVOICES
          })
        );
        dispatch(fetchInvoices());
        if (payload.isPartialInvoice) {
          if (payload.linkedDocuments?.[0]?.documentType === DOC_TYPE.QUOTE) {
            dispatch(fetchQuotes());
          }
          if (
            payload.linkedDocuments?.[0]?.documentType === DOC_TYPE.SALES_ORDER
          ) {
            dispatch(fetchSalesOrders());
          }
        }
      }
    );
  };

  const updateInvoice = async (closeOnUpdate = true) => {
    setButtonStatus(true);
    setValidationDisplayStatus(true);

    let payload: any = deepClone(updatedInvoice);
    payload = { ...payload, contact: payload.contactDto };
    payload = updateAddressAsPerLocationCF(payload);

    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      payload = {
        ...payload,
        salesInvoiceItems: rebuildCascadingDiscountsForSaving(payload)
      };
    }

    if (payload.attachmentIds?.length) {
      payload.attachments = payload.attachmentIds.map(
        (attachmentId: any) => `${attachmentId}`
      );
    }

    if (zeroAmountDocument) {
      payload.dueAmount = payload.totalAmount;
      payload.totalAmountInBaseCurrency = payload.totalAmount;
      payload.paymentStatus = PAYMENT_STATUS.PENDING;
    }

    if (payload.reservedStock) {
      const isTrackedOrBOM = payload.salesInvoiceItems.some(
        (obj: any) =>
          obj.product.type === PRODUCT_TYPE.TRACKED ||
          obj.product.type === PRODUCT_TYPE.BILL_OF_MATERIALS
      );
      if (!isTrackedOrBOM) {
        payload = {
          ...payload,
          reservedStock: false
        };
      } else {
        payload = handleReservedQuantityDataObject(
          payload,
          'salesInvoiceItems'
        );
      }
    }

    if (!isDocValid(payload)) {
      setButtonStatus(false);
      return;
    }

    const processedCreditLimitObj = await checkCreditLimit(payload);
    if (
      processedCreditLimitObj &&
      processedCreditLimitObj.showAlertPopup &&
      processedCreditLimitObj.settings.creditLimitType !==
        CREDIT_LIMIT_TYPE.IGNORE
    ) {
      showCreditLimitAlert(
        processedCreditLimitObj.settings,
        payload,
        true,
        closeOnUpdate,
        true
      );
    } else {
      makeUpdateAPICall(payload, closeOnUpdate);
    }
  };

  const makeUpdateAPICall = (payload: any, closeOnUpdate: boolean) => {
    delete payload?.items;
    delete payload?.contactDto;

    payload = removeUnwantedPayloadKeysForDocument(payload);

    InvoiceService.updateInvoice(payload, updatedInvoice?.id as number).then(
      (response: any) => {
        if (props.draftData) {
          if (closeOnUpdate) {
            dispatch(removeDraft(props.draftData.id));
          }
          updateForm(response, true, closeOnUpdate);
          setButtonStatus(false);
        }
        dispatch(
          fetchDrafts({
            tableId: draftsTableId,
            isSaveColumnId: isSavedColumnId,
            draftTypeColId: draftTypeColId,
            draftTypeColValue: LABELS.INVOICES
          })
        );
        dispatch(fetchInvoices());
        setButtonStatus(false);
        replaceURLToModuleURL();
        if (payload?.paymentMilestoneFlag) {
          dispatch(
            documentUpdated({
              newData: payload,
              oldData: props.populateFormData
            })
          );
        }
      },
      (err) => {
        console.error('Error while updating invoice: ', err);
        setButtonStatus(false);
        showAlertOnDocAPIError(err);
        replaceURLToModuleURL();
      }
    );
  };

  const handleDocumentUpdate = (doc: Document) => {
    if (tenantInfo.additionalSettings?.CASCADING_DISCOUNTS?.enable) {
      doc = {
        ...doc,
        items: rebuildCascadingDiscountsForSaving(doc)
      };
    }
    let invoiceWithUpdate = {
      ...updatedInvoice,
      ...doc
    };

    const extraKeys = {
      salesInvoiceItems: doc.items,
      salesInvoiceDueDate: doc.validTillDate,
      salesInvoiceDate: doc.documentDate,
      shipByDate: doc.fulfillmentDate,
      priceListId: doc?.priceListId,
      priceListName: doc?.priceListName,
      documentType: DOC_TYPE.INVOICE,
      currency: doc.currency,
      currencyCode: doc.currencyCode,
      entityId:
        props.documentMode === DOCUMENT_MODE.EDIT
          ? updatedInvoice?.id
          : undefined,
      documentCode:
        props.documentMode === DOCUMENT_MODE.EDIT
          ? updatedInvoice?.salesInvoiceCode
          : undefined,
      totalAmount: doc.totalAmount
    };

    invoiceWithUpdate = {
      ...invoiceWithUpdate,
      ...extraKeys
    };
    setUpdatedInvoice(invoiceWithUpdate);
  };

  const sendTriggerOnApproval = (payload: any) => {
    let emails = Utility.getApproverEmail(payload);
    let sum =
      payload &&
      payload.salesInvoiceItems
        .map((item: any) => item.totalAmount)
        .reduce((prev: any, curr: any) => prev + curr, 0);
    let payloadObj = {
      contactCode: payload.contactCode,
      totalAmount: NumberFormatService.getNumber(sum),
      userName: AuthService.getUserName(),
      currency: Utility.getCurrencySymbolFromCode(payload.currency),
      approverMap: Object.fromEntries(emails),
      currentLevel: 1,
      approvalHistory: payload['multiApprovalDetails']?.approvalHistory || [],
      draftCode: payload?.draftDocumentSequenceCode
        ? payload?.draftDocumentSequenceCode
        : '',
      contactName: payload?.contact?.name ? payload?.contact?.name : '',
      contactDocCode: payload?.contact?.documentSequenceCode
        ? payload?.contact?.documentSequenceCode
        : '',
      linkedDocument: !Utility.isEmpty(payload.linkedDocuments)
        ? payload?.linkedDocuments?.[0]?.documentType.replace('_', ' ')
        : '',
      linkedDocumentCode: !Utility.isEmpty(payload.linkedDocuments)
        ? payload?.linkedDocuments?.[0]?.documentSequenceCode
        : ''
    };
    InvoiceService.sendTriggerOnApproval(payloadObj).then(
      (response: any) => {},
      (err) => {
        console.error('Error while creating draft: ', err);
      }
    );
  };

  return (
    <>
      <NewDocument2
        permissionKeys={PERMISSIONS_BY_MODULE.INVOICE}
        booksDocument={invoice}
        documentMode={props.documentMode}
        draftData={props.draftData}
        draftType={props.draftData.draftType}
        canValidate={props.draftData.canValidate}
        onDocumentUpdate={(x: Document) => handleDocumentUpdate(x)}
        isCenterAlign={isCenterAlign}
        isDocumentLoading={props.draftData.isLoading}
        updateDocWithoutClosing={() => updateInvoice(false)}
        autoProcessDoc={autoProcessDoc.current}
        auditLogView={
          props.draftData?.data?.hideSave || props.auditLogView || false
        }
      />
      {showPeppolDialog && (
        <Peppol
          onCancel={() => setShowPeppolDialog(false)}
          draftId={props.draftData.id}
        />
      )}
      {showFulfillmentPopup && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={localizedText('Direct Fulfillment of Invoice')}
          height={'auto'}
          minHeight={'50%'}
          width={'90%'}
          disableClickOutside={true}
          btnList={fulfillmentPopupBtnConfig}
        >
          <Fulfillment
            documentDetails={docForFulfillment}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
            closePopup={() => {
              setShowFulfillmentPopup(false);
            }}
            closeMenuContainer={() => {
              dispatch(removeDraft(props.draftData.id));
            }}
            documentType={DOC_TYPE.INVOICE}
          />
        </PopupWrapper>
      )}
    </>
  );
}
