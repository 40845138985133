import {
  COMPONENTLIST_GROUP_IDS,
  DOCUMENT_MODE,
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  PRODUCT_TYPE
} from '../../Constants/Constant';
import { Quote } from '../../Models/Quote';
import {
  addOperationsAndAdditionalCostToBomComponentGroups,
  defaultAdditionalChargesObject,
  getContactObjWhenDocIsLoading,
  getTenantTaxSystem
} from '../../SharedComponents/DocumentForm/NewDocumentHelper';
import Utility from '../../Utility/Utility';

export const quoteEmailTemplateConfig: any = [
  {
    hidden: false,
    text: 'Contact Name',
    action: 'contactName'
  },
  {
    hidden: false,
    text: 'User Name',
    action: 'userName'
  },
  {
    hidden: false,
    text: 'Quote Date',
    action: 'quoteDate'
  },
  {
    hidden: false,
    text: 'Quote Amount',
    action: 'amount'
  },
  {
    hidden: false,
    text: 'Currency Code',
    action: 'currencyCode'
  },
  {
    hidden: false,
    text: 'Currency Symbol',
    action: 'currencySymbol'
  },
  {
    hidden: false,
    text: 'Company Name',
    action: 'companyName'
  },
  {
    hidden: false,
    text: 'Quotation Number',
    action: 'quotationNumber'
  }
];

export const setItemsFromQuotationItemDtoList = (doc: any, docMode:any) => {
  let contactObj = getContactObjWhenDocIsLoading(doc);
  const document = {
    ...doc,
    items: doc.quotationItemDtoList.map((item: any) => {
      const compGroupDetails =
        Utility.isComponentDetailsForFGOnInvoiceSOQuote() &&
        item?.product?.type === PRODUCT_TYPE.BILL_OF_MATERIALS &&
        Utility.isNotEmpty(item?.bomComponentGroupDetails)
          ? {
              ...item?.bomComponentGroupDetails,
              bomComponentGroups:
                addOperationsAndAdditionalCostToBomComponentGroups(item)
            }
          : null;
      return {
        ...item,
        availableQuantity: item?.product?.availableQuantity,
        taxSystem: getTenantTaxSystem(),
        unitPriceGstInclusive: doc.unitPriceGstInclusive,
        documentSequenceCode: item?.product?.documentSequenceCode,
        bomComponentGroupDetails: compGroupDetails,
        unmodifiedBomComponentGroupDetails: compGroupDetails
      };
    }),
    contact: contactObj,
    documentType: DOC_TYPE.QUOTE,
    fulfillmentDate: doc.shipByDate,
    oldDueAmount: doc.dueAmount,
    additionalCharges: !Utility.isEmpty(doc.additionalCharges)
      ? doc.additionalCharges
      : { ...defaultAdditionalChargesObject }
  };
  if((docMode === DOCUMENT_MODE.VIEW || docMode === DOCUMENT_MODE.EDIT) && Utility.isNotEmpty(doc?.contact?.name)){
    document.contact = {...document?.contact,name: doc?.contact?.name ?? document?.contact?.name ?? '' }
  }     
  return document;
};

export const getUpdatedQuoteObject = (props: Quote) => {
  let updatedObj = {
    ...props,
    documentType: DOC_TYPE.QUOTE,
    fulfillmentStatus:
      props.fulfillmentStatus || FULFILLMENT_STATUS.UNFULFILLED,
    fulfillmentType: props.fulfillmentType,
    documentCode: Utility.getValue(props.documentCode, props.quotationCode),
    fulfillmentDate: Utility.getValue(props.fulfillmentDate, props.shipByDate),
    status: !props.id ? DOCUMENT_STATUS.OPEN : props.status,
    backOrder: props.backOrder ? props.backOrder : false,

    items: props.quotationItemDtoList?.map((item) => {
      return {
        ...item,
        availableQuantity: item?.product?.availableQuantity,
        advancedTracking: item?.product?.advancedTracking,
        unitPriceGstInclusive: props.unitPriceGstInclusive,
        documentSequenceCode: item?.product?.documentSequenceCode,
        exchangeRate: props.exchangeRate,
        documentItemCode: Utility.getValue(
          item.documentItemCode,
          item.quotationItemCode,
          item.salesInvoiceItemCode
        )
      };
    }),
    linkedSalesInvoices: props.linkedSalesInvoices,
    processedInPPS: props.processedInPPS,
    reservedStock: props.reservedStock ? props.reservedStock : false
  };

  return updatedObj;
};
