import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKSpinner,
  DKTooltipWrapper,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ic_warning_red from '../../../Assets/Icons/ic_warning_red.png';
import {
  API_STATUS,
  BOOKS_DATE_FORMAT,
  DOCUMENT_TYPE,
  DOC_TYPE,
  MODULE_TYPE,
  POPUP_CALLBACKS_TYPE,
  QTY_ROUNDOFF_PRECISION,
  STATUS_TYPE,
  TRACKING_TYPE
} from '../../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchBatchTrackingProducts,
  selectBatchTrackingProduct,
  selectBatchTrackingProductsLoadingStatus
} from '../../../Redux/Slices/BatchTrackingSlice';
import Utility, {
  convertBooksDateFormatToUILibraryFormat
} from '../../../Utility/Utility';

import { addYears, parseISO } from 'date-fns';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import { selectAdvancedTrackingData } from '../../../Redux/Slices/AdvancedTrackingDataSlice';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import { selectedWarehouseWithRRBCombination } from '../../../Redux/Slices/WarehouseSlice';
import DateFormatService from '../../../Services/DateFormat';
import SourceDestinationWarehouseManagementRRB from '../../../SharedComponents/WarehouseManagement/SourceDestinationWarehouseManagementRRB';
import WarehouseService from '../../../Services/Warehouse';
import {
  fetchSerialTrackingProductsJWO,
  selectSerialTrackingProductJWO
} from '../../../Redux/Slices/SerialTrackingProductJWOSlice';
import { selectBatchSerialCustomFields } from '../../../Redux/Slices/CommonDataSlice';
import { getNewColumn } from '../../Accounting/JournalEntry/JEHelper';

const StockTransferAdvancedBatchTrackingPopup: React.FC<any> = (props) => {
  const { t, i18n } = useTranslation();
  const [item, setItem] = useState(props.itemDetails);
  const [stocksAvailable, setStockAvailable] = useState(
    item?.product?.availableQuantity
  );

  const [pendingQuantity, setPendingQuantity] = useState(
    item.quantityRequired || Utility.pendingToBeReceivedQuantity(item)
  );
  const [readOnlyTarget, setReadOnlyTarget] = useState(false);
  const [totalAllocatedItem, setTotalAllocatedItem] = useState(0);
  const [moduleName, setModuleName] = useState(props.module);
  const productWarehouse = useAppSelector(selectBatchTrackingProduct);
  const productInventoryWarehouse = props?.selectedWarehouseProductsData;
  const [localWarehouse, setLocalWarehouse] = useState<any[]>(productWarehouse);
  const [availableBatchData, setAvailableBatchData] = useState<any[]>([]);
  const [availableBatchDataNonFiltered, setAvailableBatchDataNonFiltered] =
    useState<any[]>([]);
  const [destinationBatchData, setDestinationBatchData] = useState<any[]>([]);
  const [warehouseData, setWarehouseData] = useState<any>([]);
  const [sourceWarehouse, setSourceWarehouse] = useState<any>({});
  const [sourceRRB, setSourceRRB] = useState<any>({
    srcRowCode: null,
    srcRackCode: null,
    srcBinCode: null
  });
  const [destinationWarehouse, setDestinationWarehouse] = useState<any>({});
  const [destinationRRB, setDestinationRRB] = useState<any>({
    destRowCode: null,
    destRackCode: null,
    destBinCode: null
  });
  const advancedTrackingProductsData = useAppSelector(
    selectAdvancedTrackingData
  );
  const allWarehouseData: any = useAppSelector(
    selectedWarehouseWithRRBCombination
  );
  const [defaultProductWarehouse, setDefaultProductWarehouse] = useState(
    props.defaultProductWarehouse
  );

  const tenantInfo = useAppSelector(activeTenantInfo);
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);
  const [gridColumns, setGridColumns] = useState<any[]>([]);
  const [gridData, setGridData] = useState<any>([
    {
      warehouseCode: '',
      serialBatchNumber: '',
      manufacturingDate: '',
      expiryDate: '',
      batchSize: 0,
      batchSizeFulfilled: 0,
      invalidFields: ['warehouseCode', 'serialBatchNumber']
    }
  ]);
  const [requiredQuantity, setRequiredQuantity] = useState<any>(
    item.requiredQuantity * props.buildQuantity
  );
  const dispatch = useAppDispatch();
  const loadingBatchData = useAppSelector(
    selectBatchTrackingProductsLoadingStatus
  );
  const [stockTransferItem, setStockTransferItem] = useState(
    props.stockTransferData
  );
  const [currentIndex, setCurrentIndex] = useState(props.currentIndex);
  const [jwoWarehouseFetched, setJwoWarehouseFetched] = useState<any>();
  const selectProductJWOList = useAppSelector(selectSerialTrackingProductJWO);
  const usedQty = props.stockTransferData[props.currentIndex]
    ?.stockTransferWarehouseInventoryData
    ? props.stockTransferData[props.currentIndex]
        ?.stockTransferWarehouseInventoryData.quantity
    : 0;
  const [sharedAvailableQty, setSharedAvailableQty] = useState(
    props.stockTransferData && props.stockTransferData[currentIndex]
      ? props.stockTransferData[currentIndex].availableQuantity + usedQty
      : 0
  );

  useEffect(() => {
    loadJWOWH();
    let code = props.itemDetails.pid
      ? props.itemDetails.pid
      : props.itemDetails.productId;
    dispatch(
      fetchBatchTrackingProducts({
        productCode: code,
        enableQCWarehouse: false,
        includeRejectedWarehouse: true
      })
    );
    let initialGridData: any = [];
    let serialData: any[] = [];
    let updatedData: any[] = [];
    let result: any[] = [];
    if (moduleName === MODULE_TYPE.SELL) {
      result = localWarehouse.find(
        (warehouse: any) => warehouse.primary
      )?.advancedTrackingMeta;
      serialData = result?.filter(
        (serial: any) => serial.batchSizeFulfilled < serial.batchSize
      );
      if (!Utility.isEmpty(props.targetWarehouse)) {
        if (!Utility.isEmpty(productInventoryWarehouse)) {
          if (productInventoryWarehouse) {
            let warehouseIdWithInventory: any[] = [];
            productInventoryWarehouse?.forEach((piw: any) => {
              if (
                piw.productAvailableQuantity &&
                piw.productAvailableQuantity[item.pid] &&
                piw.productAvailableQuantity[item.pid] > 0
              ) {
                //get list of warehouse where product is available
                warehouseIdWithInventory.push(piw.id);
              }
            });
            if (warehouseIdWithInventory.length > 0) {
              //remove targetwarehouse from list
              let x = warehouseIdWithInventory.filter(
                (w: any) => w.id !== props.targetWarehouse.id
              );
              if (x && x.length > 0) {
                //set target warehouse if there are inventory in other warehouse
                let tmp = productInventoryWarehouse?.filter(
                  (w: any) => w.id === props.targetWarehouse.id
                );
                if (tmp && tmp.length > 0) {
                  setDestinationWarehouse(tmp[0]);
                  setReadOnlyTarget(true);
                }
              }
            }
          }
        }
      }
      if (
        !Utility.isEmpty(
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
        )
      ) {
        let srcWh = getActiveWarehouses()?.find(
          (ele: any) =>
            ele.code ==
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcWarehouseCode
        );

        result = localWarehouse.find(
          (warehouse: any) => warehouse.code === srcWh.code
        )?.advancedTrackingMeta;
        serialData = result?.filter(
          (serial: any) => serial.batchSizeFulfilled < serial.batchSize
        );

        if (serialData && serialData.length > 0) {
          let destWh = getDestinationWarehouse()?.find(
            (ele: any) =>
              ele.code ==
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destWarehouseCode
          );
          let filteredRacksDataForSourceWarehouse: any[] =
            srcWh?.warehouseRackInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRowCode
          ) {
            filteredRacksDataForSourceWarehouse =
              srcWh?.warehouseRackInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.srcRowCode
              );
          }

          let filteredBinDataForSourceWarehouse: any[] =
            srcWh?.warehouseBinInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRackCode
          ) {
            filteredBinDataForSourceWarehouse =
              srcWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rackCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.srcRackCode
              );
          } else {
            filteredBinDataForSourceWarehouse =
              srcWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.srcRowCode
              );
          }

          let filteredRacksDataForDestinationWarehouse: any[] =
            destWh?.warehouseRackInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.destRowCode
          ) {
            filteredRacksDataForDestinationWarehouse =
              destWh?.warehouseRackInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.destRowCode
              );
          }

          let filteredBinDataForDestinationWarehouse: any[] =
            destWh?.warehouseBinInfos || [];
          if (
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.destRackCode
          ) {
            filteredBinDataForDestinationWarehouse =
              destWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rackCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.destRackCode
              );
          } else {
            filteredBinDataForDestinationWarehouse =
              destWh?.warehouseBinInfos?.filter(
                (rack: any) =>
                  rack.rowCode ===
                  stockTransferItem[currentIndex]
                    .stockTransferWarehouseInventoryData?.destRowCode
              );
          }

          srcWh = {
            ...srcWh,
            srcRowCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcRowCode ?? null,
            srcRackCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcRackCode ?? null,
            srcBinCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.srcBinCode ?? null,
            filteredRackInfos: filteredRacksDataForSourceWarehouse,
            filteredBinInfos: filteredBinDataForSourceWarehouse
          };
          destWh = {
            ...destWh,
            destRowCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRowCode ?? null,
            destRackCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRackCode ?? null,
            destBinCode:
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destBinCode ?? null,
            filteredRackInfos: filteredRacksDataForDestinationWarehouse,
            filteredBinInfos: filteredBinDataForDestinationWarehouse
          };
          setSourceWarehouse(srcWh);
          setDestinationWarehouse(destWh);
          setSourceRRB({
            ...srcWh,
            srcRowCode: srcWh?.srcRowCode ?? null,
            srcRackCode: srcWh?.srcRackCode ?? null,
            srcBinCode: srcWh?.srcBinCode ?? null
          });
          setDestinationRRB({
            ...destWh,
            destRowCode: destWh?.destRowCode ?? null,
            destRackCode: destWh?.destRackCode ?? null,
            destBinCode: destWh?.destBinCode ?? null
          });
          const allocatedBatch = stockTransferItem[
            currentIndex
          ].stockTransferWarehouseInventoryData?.advancedTrackingData?.filter(
            (a: any) =>
              serialData.map((e: any) => {
                if (
                  a.sourceSerialBatchNumber === e.serialBatchNumber &&
                  e.rowCode ===
                    stockTransferItem[currentIndex]
                      ?.stockTransferWarehouseInventoryData?.srcRowCode &&
                  e.rackCode ===
                    stockTransferItem[currentIndex]
                      ?.stockTransferWarehouseInventoryData?.srcRackCode &&
                  e.binCode ===
                    stockTransferItem[currentIndex]
                      ?.stockTransferWarehouseInventoryData?.srcBinCode
                ) {
                  const item = {
                    ...a,
                    warehouseCode: localWarehouse.find(
                      (warehouse: any) => warehouse.code === a.warehouseCode
                    ),
                    serialBatchNumber: serialData.find(
                      (serialItem: any) =>
                        serialItem.serialBatchNumber ===
                        a.sourceSerialBatchNumber
                    ),
                    manufacturingDate: DateFormatService.getFormattedDateString(
                      a.manufacturingDate,
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    ),
                    expiryDate: DateFormatService.getFormattedDateString(
                      a.expiryDate,
                      BOOKS_DATE_FORMAT['DD-MM-YYYY']
                    ),
                    batchSize: e.batchSize - e.batchSizeFulfilled,
                    transferQuantity: a.qtyToTransfer,
                    destinationBatch: a.destSerialBatchNumber,
                    batchSizeFulfilled: a.qtyToTransfer,
                    invalidFields: []
                  };
                  updatedData.push(item);
                }
              })
          );
          initialGridData = updatedData;
          let totalItem = updatedData.reduce(
            (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
            0
          );
          setTotalAllocatedItem(totalItem);
          setAvailableBatchData(serialData);
          setAvailableBatchDataNonFiltered(serialData);
          let result = productWarehouse?.find(
            (warehouse: any) => warehouse.code === destWh.code
          )?.advancedTrackingMeta;
          if (!Utility.isEmpty(result)) {
            setDestinationBatchData(result);
          } else {
            setDestinationBatchData([]);
          }
        }
      } else {
        initialGridData = [
          {
            warehouseCode: '',
            serialBatchNumber: '',
            manufacturingDate: '',
            expiryDate: '',
            batchSize: 0,
            batchSizeFulfilled: 0,
            invalidFields: ['warehouseCode', 'serialBatchNumber']
          }
        ];
      }
    }

    setGridData(initialGridData);
  }, []);

  const loadJWOWH = async () => {
    let findJWOWarehouse = allWarehouseData?.content?.find(
      (warehouse: any) => warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
    );

    if (Utility.isEmpty(findJWOWarehouse)) {
      const jwoWH = await WarehouseService.getJWOWarehouses();
      if (Utility.isNotEmpty(jwoWH)) {
        setJwoWarehouseFetched(jwoWH);
      }
    }
    if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      props.stockTransferDocumentSeqCodes &&
      props.stockTransferDocumentSeqCodes.length !== 0
    ) {
      dispatch(
        fetchSerialTrackingProductsJWO(props.stockTransferDocumentSeqCodes)
      );
    }
  };

  useEffect(() => {
    let initialGridData: any = [];
    let serialData: any[] = [];
    let updatedData: any[] = [];
    let result: any[] = [];

    if (
      !Utility.isEmpty(
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
      )
    ) {
      let srcWh = getActiveWarehouses()?.find(
        (ele: any) =>
          ele.code ==
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcWarehouseCode
      );
      let destWh = getDestinationWarehouse()?.find(
        (ele: any) =>
          ele.code ==
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destWarehouseCode
      );

      result = localWarehouse.find(
        (warehouse: any) => warehouse.code == srcWh?.code
      )?.advancedTrackingMeta;
      serialData = result?.filter(
        (serial: any) => serial.batchSizeFulfilled < serial.batchSize
      );
      let filteredRacksDataForSourceWarehouse: any[] =
        srcWh?.warehouseRackInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.srcRowCode
      ) {
        filteredRacksDataForSourceWarehouse = srcWh?.warehouseRackInfos?.filter(
          (rack: any) =>
            rack.rowCode ===
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRowCode
        );
      }

      let filteredBinDataForSourceWarehouse: any[] =
        srcWh?.warehouseBinInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.srcRackCode
      ) {
        filteredBinDataForSourceWarehouse = srcWh?.warehouseBinInfos?.filter(
          (rack: any) =>
            rack.rackCode ===
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRackCode
        );
      } else {
        filteredBinDataForSourceWarehouse = srcWh?.warehouseBinInfos?.filter(
          (rack: any) =>
            rack.rowCode ===
            stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
              ?.srcRowCode
        );
      }

      let filteredRacksDataForDestinationWarehouse: any[] =
        destWh?.warehouseRackInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.destRowCode
      ) {
        filteredRacksDataForDestinationWarehouse =
          destWh?.warehouseRackInfos?.filter(
            (rack: any) =>
              rack.rowCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRowCode
          );
      }

      let filteredBinDataForDestinationWarehouse: any[] =
        destWh?.warehouseBinInfos || [];
      if (
        stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
          ?.destRackCode
      ) {
        filteredBinDataForDestinationWarehouse =
          destWh?.warehouseBinInfos?.filter(
            (rack: any) =>
              rack.rackCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRackCode
          );
      } else {
        filteredBinDataForDestinationWarehouse =
          destWh?.warehouseBinInfos?.filter(
            (rack: any) =>
              rack.rowCode ===
              stockTransferItem[currentIndex]
                .stockTransferWarehouseInventoryData?.destRowCode
          );
      }

      srcWh = {
        ...srcWh,
        srcRowCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcRowCode ?? null,
        srcRackCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcRackCode ?? null,
        srcBinCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.srcBinCode ?? null,
        filteredRackInfos: filteredRacksDataForSourceWarehouse,
        filteredBinInfos: filteredBinDataForSourceWarehouse
      };
      destWh = {
        ...destWh,
        destRowCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destRowCode ?? null,
        destRackCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destRackCode ?? null,
        destBinCode:
          stockTransferItem[currentIndex].stockTransferWarehouseInventoryData
            ?.destBinCode ?? null,
        filteredRackInfos: filteredRacksDataForDestinationWarehouse,
        filteredBinInfos: filteredBinDataForDestinationWarehouse
      };
      setSourceWarehouse(srcWh);
      setDestinationWarehouse(destWh);
      setSourceRRB({
        ...srcWh,
        srcRowCode: srcWh?.srcRowCode ?? null,
        srcRackCode: srcWh?.srcRackCode ?? null,
        srcBinCode: srcWh?.srcBinCode ?? null
      });
      setDestinationRRB({
        ...destWh,
        destRowCode: destWh?.destRowCode ?? null,
        destRackCode: destWh?.destRackCode ?? null,
        destBinCode: destWh?.destBinCode ?? null
      });
      const allocatedBatch = stockTransferItem[
        currentIndex
      ].stockTransferWarehouseInventoryData?.advancedTrackingData?.filter(
        (a: any) =>
          serialData?.map((e: any) => {
            if (
              a.sourceSerialBatchNumber === e.serialBatchNumber &&
              e.rowCode ===
                stockTransferItem[currentIndex]
                  ?.stockTransferWarehouseInventoryData?.srcRowCode &&
              e.rackCode ===
                stockTransferItem[currentIndex]
                  ?.stockTransferWarehouseInventoryData?.srcRackCode &&
              e.binCode ===
                stockTransferItem[currentIndex]
                  ?.stockTransferWarehouseInventoryData?.srcBinCode
            ) {
              const item = {
                ...a,
                warehouseCode: localWarehouse.find(
                  (warehouse: any) => warehouse.code === a.warehouseCode
                ),
                serialBatchNumber: serialData.find(
                  (serialItem: any) =>
                    serialItem.serialBatchNumber === a.sourceSerialBatchNumber
                ),
                manufacturingDate: DateFormatService.getFormattedDateString(
                  a.manufacturingDate,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                ),
                expiryDate: DateFormatService.getFormattedDateString(
                  a.expiryDate,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                ),
                batchSize: e.batchSize - e.batchSizeFulfilled,
                transferQuantity: a.qtyToTransfer,
                destinationBatch: a.destSerialBatchNumber,
                batchSizeFulfilled: a.qtyToTransfer,
                invalidFields: []
              };
              updatedData.push(item);
            }
          })
      );
      initialGridData = updatedData;
      let totalItem = updatedData.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
        0
      );
      setTotalAllocatedItem(totalItem);
      setAvailableBatchData(serialData);
      setAvailableBatchDataNonFiltered(serialData);

      let destBatches = productWarehouse?.find(
        (warehouse: any) => warehouse.code === destWh.code
      )?.advancedTrackingMeta;
      if (!Utility.isEmpty(destBatches)) {
        setDestinationBatchData(destBatches);
      } else {
        setDestinationBatchData([]);
      }
      setGridData(initialGridData);
    }
  }, [props]);

  useEffect(() => {
    let tempAvailableBatchData = availableBatchDataNonFiltered?.filter(
      (batch: any) => {
        return (
          batch.rowCode === sourceRRB?.srcRowCode &&
          batch.rackCode === sourceRRB?.srcRackCode &&
          batch.binCode === sourceRRB?.srcBinCode
        );
      }
    );
    setAvailableBatchData(tempAvailableBatchData);
    setAvailableBatchData(
      tempAvailableBatchData?.length > 0
        ? tempAvailableBatchData
        : availableBatchDataNonFiltered
    );
    // setGridData([
    //   {
    //     warehouseCode: '',
    //     serialBatchNumber: '',
    //     manufacturingDate: '',
    //     expiryDate: '',
    //     batchSize: 0,
    //     batchSizeFulfilled: 0,
    //     invalidFields: ['warehouseCode', 'serialBatchNumber']
    //   }
    // ]);
  }, [sourceRRB]);

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */

    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.BACKORDER,
        data: item
      });
    }
  };

  useEffect(() => {
    registerInteractions();
  });

  useEffect(() => {
    getDataGridColumns();
  }, [batchSerialCFfromStore, availableBatchData, gridData]);

  useEffect(() => {
    getDataGridColumns();
  }, []);

  useEffect(() => {
    let warehouseTempData =
      props.hasMultilpleWarehouseData &&
      props.hasMultilpleWarehouseData.length > 0
        ? props.hasMultilpleWarehouseData
        : [
            {
              warehouseCode: defaultProductWarehouse,
              availableQty: 0,
              quantity: 0
            }
          ];

    setWarehouseData(warehouseTempData);
  }, [defaultProductWarehouse, defaultProductWarehouse]);

  useEffect(() => {
    if (!Utility.isEmpty(productWarehouse)) {
      if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
        getJWDestinationWarehouse();
      }
    }
  }, [productWarehouse]);

  const onRowUpdate = (val: any) => {
    const key = val['columnKey'];
    const lineItem = val['rowIndex'];
    const item = val['rowData'];

    let tmpData = [...gridData];

    let invalidFields: any[] = tmpData[val['rowIndex']]['invalidFields'];
    if (key === 'warehouseCode') {
      let warehouseData;
      let result;
      let batchData;
      if (moduleName === MODULE_TYPE.SELL) {
        warehouseData = productWarehouse;
        result = warehouseData.find(
          (warehouse: any) => warehouse.code === item.warehouseCode.code
        )?.advancedTrackingMeta;

        batchData = result?.filter(
          (serial: any) => serial.batchSizeFulfilled < serial.batchSize
        );
      }
      tmpData[val['rowIndex']]['invalidFields'] = invalidFields.filter(
        (e) => e !== 'warehouseCode'
      );
      setAvailableBatchData(batchData);
    } else if (key === 'serialBatchNumber') {
      if (moduleName === MODULE_TYPE.SELL) {
        let duplicateData: any[] = tmpData.filter((sr: any) => {
          return (
            sr.serialBatchNumber.serialBatchNumber ===
            item.serialBatchNumber.serialBatchNumber
          );
        });
        if (duplicateData && duplicateData.length > 1) {
          tmpData[val['rowIndex']]['invalidFields'] = ['serialBatchNumber'];
        } else {
          tmpData[val['rowIndex']]['invalidFields'] = invalidFields.filter(
            (e) => e !== 'serialBatchNumber'
          );
        }
        // if (props?.itemDetails?.advancedTracking === ADVANCE_TRACKING.BATCH) {
        //   tmpData[val['rowIndex']]['invalidFields'].push('destinationBatch');
        // }
      }
      tmpData[val['rowIndex']]['batchSize'] =
        item.serialBatchNumber.batchSize -
        item.serialBatchNumber.reservedQuantity -
        item.serialBatchNumber.batchSizeFulfilled;
      tmpData[val['rowIndex']]['manufacturingDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.manufacturingDate
          : tmpData[val['rowIndex']]['manufacturingDate'];
      tmpData[val['rowIndex']]['expiryDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.expiryDate
          : tmpData[val['rowIndex']]['expiryDate'];
      tmpData[val['rowIndex']]['editable'] = false;
      tmpData[val['rowIndex']]['destinationBatch'] =
        item.serialBatchNumber?.serialBatchNumber || item.serialBatchNumber;
      tmpData[val['rowIndex']] = addProductCustomFieldsToLineItem({
        ...tmpData[val['rowIndex']],
        customField: item.serialBatchNumber.customField
      });
    } else if (key === 'batchSizeFulfilled') {
      let totalItem = 0;
      if (moduleName === MODULE_TYPE.SELL) {
        if (
          parseFloat(item['batchSizeFulfilled']) > parseFloat(item['batchSize'])
        ) {
          invalidFields.push('batchSizeFulfilled');
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        } else {
          invalidFields = invalidFields.filter(
            (e) => e !== 'batchSizeFulfilled'
          );
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        }
        totalItem = gridData.reduce(
          (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
          0
        );
        setTotalAllocatedItem(totalItem);
      }
    } else if (key === 'batchSize') {
      let totalItem = 0;
      if (moduleName === MODULE_TYPE.BUY) {
        if (parseFloat(item['batchSize']) > parseFloat(pendingQuantity)) {
          invalidFields.push('batchSize');
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        } else {
          invalidFields = invalidFields.filter((e) => e !== 'batchSize');
          tmpData[lineItem] = { ...tmpData[lineItem], invalidFields };
        }
        totalItem = gridData.reduce(
          (a: any, b: any) => +a + +parseFloat(b.batchSize),
          0
        );
      }
      setTotalAllocatedItem(totalItem);
    } else if (key === 'manufacturingDate') {
      let isoDate = tmpData[val['rowIndex']]['expiryDate']
        ? addYears(
            parseISO(tmpData[val['rowIndex']]['expiryDate']),
            1
          ).toISOString()
        : addYears(
            parseISO(tmpData[val['rowIndex']]['manufacturingDate']),
            1
          ).toISOString();
      tmpData[val['rowIndex']]['manufacturingDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.manufacturingDate
          : tmpData[val['rowIndex']]['manufacturingDate'];
      tmpData[val['rowIndex']]['expiryDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.expiryDate
          : isoDate;
    } else if (key === 'expiryDate') {
      tmpData[val['rowIndex']]['expiryDate'] =
        moduleName === MODULE_TYPE.SELL
          ? item.serialBatchNumber.expiryDate
          : tmpData[val['rowIndex']]['expiryDate'];
    } else if (key === 'destinationBatch') {
      tmpData[val['rowIndex']]['destinationBatch'] = item.destinationBatch
        ?.serialBatchNumber
        ? item.destinationBatch?.serialBatchNumber
        : item.destinationBatch;
      tmpData[val['rowIndex']]['editable'] = false;
      tmpData[val['rowIndex']]['invalidFields'] = tmpData[val['rowIndex']][
        'invalidFields'
      ]?.filter((e: any) => e !== 'destinationBatch');
    } else if (key === 'transferQuantity') {
      tmpData[val['rowIndex']]['transferQuantity'] = isNaN(
        item?.transferQuantity
      )
        ? 0
        : Utility.roundingOff(item.transferQuantity, QTY_ROUNDOFF_PRECISION);
    }
    setGridData(tmpData);
  };

  const addNewItem = () => {
    let rows = [...gridData];
    let newRow = {
      warehouseCode: '',
      serialBatchNumber: '',
      manufacturingDate: '',
      expiryDate: '',
      batchSize: '',
      batchSizeFulfilled: 0,
      transferQuantity: 0,
      invalidFields: ['warehouseCode', 'serialBatchNumber']
    };

    rows.push(newRow);
    let totalItem = 0;

    setGridData(rows);
  };

  const onDelete = ({ rowIndex }: any) => {
    let rows = [...gridData];
    rows.splice(rowIndex, 1);
    let totalItem = 0;
    if (moduleName === MODULE_TYPE.SELL) {
      totalItem = rows.reduce(
        (a: any, b: any) => +a + +parseFloat(b.batchSizeFulfilled),
        0
      );
    }
    setTotalAllocatedItem(totalItem);
    setGridData(rows);
  };

  const getBatchTrackingGrid = () => {
    return (
      <DKDataGrid
        needShadow={false}
        needBorder={true}
        needColumnIcons={false}
        needTrailingColumn={false}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnAdd={false}
        allowColumnEdit={false}
        allowRowEdit={true}
        onRowUpdate={onRowUpdate}
        currentPage={1}
        totalPageCount={1}
        title={''}
        width={'100%'}
        dateFormat={convertBooksDateFormatToUILibraryFormat(
          tenantInfo.dateFormat
        )}
        columns={gridColumns}
        rows={gridData}
      />
    );
  };

  const getDataGridColumns = () => {
    let columns: any = [];
    if (moduleName === MODULE_TYPE.SELL) {
      columns = [
        {
          key: 'serialBatchNumber',
          name: 'Source Batch No.',
          type: INPUT_TYPE.DROPDOWN,
          textAlign: 'left',
          width: 145,
          systemField: true,
          editable: true,
          hidden: false,
          uiVisible: true,
          formatter: (obj: any) => {
            return obj.value.serialBatchNumber;
          },
          renderer: (obj: any) => {
            return <DKLabel text={`${obj.value.serialBatchNumber || ''}`} />;
          },
          dropdownConfig: {
            className: '',
            style: {},
            allowSearch: false,
            data: availableBatchData,
            renderer: (index: any, obj: any) => {
              return <DKLabel text={obj.serialBatchNumber} />;
            },
            onSelect: (index: any, value: any) => {}
          }
        },
        {
          key: 'destinationBatch',
          name: 'Destination Batch No.',
          type: INPUT_TYPE.TEXT,
          textAlign: 'left',
          width: 145,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true,
          formatter: (obj: any, index: any) => {
            return Utility.isObject(obj?.value.serialBatchNumber)
              ? obj?.value?.serialBatchNumber?.serialBatchNumber
              : obj?.value?.serialBatchNumber || obj?.value || '';
          },
          renderer: (obj: any) => {
            return (
              <DKLabel
                text={
                  obj?.value?.serialBatchNumber ||
                  obj?.value?.serialBatchNumber?.serialBatchNumber ||
                  obj.value
                }
              />
            );
          },
          dropdownConfig: {
            data: destinationBatchData,
            renderer: (obj: any) => {
              return Utility.isObject(obj?.serialBatchNumber)
                ? obj?.serialBatchNumber?.serialBatchNumber
                : obj?.serialBatchNumber || obj.value;
            }
          }
        },
        {
          key: 'manufacturingDate',
          name: 'Manufactured Date',
          textAlign: 'center',
          type: INPUT_TYPE.TEXT,
          width: 160,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true,
          renderer: (obj: any) => {
            return (
              <DKLabel
                text={`${DateFormatService.getFormattedDateString(
                  obj.value,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )}`}
              />
            );
          }
        },

        {
          key: 'expiryDate',
          name: 'Expiry Date',
          type: INPUT_TYPE.TEXT,
          width: 120,
          textAlign: 'center',
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true,
          renderer: (obj: any) => {
            return (
              <DKLabel
                text={`${DateFormatService.getFormattedDateString(
                  obj.value,
                  BOOKS_DATE_FORMAT['DD-MM-YYYY']
                )}`}
              />
            );
          }
        },
        {
          key: 'batchSize',
          name: 'Available Qty',
          type: INPUT_TYPE.NUMBER,
          textAlign: 'right',
          width: 115,
          systemField: true,
          editable: false,
          hidden: false,
          uiVisible: true
        },
        {
          key: 'transferQuantity',
          name: 'Transfer Qty',
          textAlign: 'left',
          type: INPUT_TYPE.NUMBER,
          width: 110,
          systemField: true,
          editable: true,
          hidden: false,
          uiVisible: true
        }

        // {
        //   id: 'action',
        //   key: 'action',
        //   name: '',
        //   type: INPUT_TYPE.BUTTON,
        //   width: 70,
        //   options:
        //     gridData && gridData.length > 1
        //       ? [
        //           {
        //             icon: DKIcons.ic_delete,
        //             className: ' p-0',
        //             onClick: (data: any) => onDelete(data)
        //           }
        //         ]
        //       : []
        // }
      ];
      let activeProductCustomFields = [];
      if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
        let productCf = batchSerialCFfromStore?.content?.filter((item: any) => {
          return item.status === STATUS_TYPE.ACTIVE;
        });
        activeProductCustomFields = productCf.sort(
          (field1: any, field2: any) =>
            field1.customFieldIndex - field2.customFieldIndex
        );
      }

      activeProductCustomFields?.forEach((accCF: any) => {
        let newItem: any = getNewColumn(accCF);
        newItem['editable'] = false;
        const newItemInExistingColConfig = columns.find(
          (config: any) => config.code === accCF.code
        );
        if (Utility.isEmpty(newItemInExistingColConfig)) {
          columns.push({ ...newItem });
        }
      });
      columns.push({
        id: 'action',
        key: 'action',
        name: '',
        type: INPUT_TYPE.BUTTON,
        width: 70,
        options:
          gridData && gridData.length > 1
            ? [
                {
                  icon: DKIcons.ic_delete,
                  className: ' p-0',
                  onClick: (data: any) => onDelete(data)
                }
              ]
            : []
      });
    }
    setGridColumns(columns);
    return columns;
  };

  const getActiveWarehouses = () => {
    if (loadingBatchData !== API_STATUS.LOADING) {
      return productWarehouse && productWarehouse.length > 0
        ? productWarehouse
        : [];
    } else {
      return [];
    }
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto gap-2">
          <DKLabel text="Stock Transfer Batch Products" className="fw-m fs-l" />
          {loadingBatchData === API_STATUS.LOADING && <DKSpinner />}
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />

          <DKButton
            title={'Save'}
            className={'bg-button text-white mr-r'}
            onClick={() => {
              onStockTransferSave();
            }}
          />
        </div>
      </div>
    );
  };

  const validateDetail = () => {
    if (Utility.isEmpty(sourceWarehouse)) {
      showAlert('Error', 'Please select source warehouse');
      return false;
    }
    if (Utility.isEmpty(destinationWarehouse)) {
      showAlert('Error', 'Please select Target warehouse');
      return false;
    }
    let sourceBatchEmpty = gridData.filter(
      (item: any) => item.serialBatchNumber === ''
    );
    if (sourceBatchEmpty && sourceBatchEmpty.length > 0) {
      showAlert('Error', 'Please select source batch number');
      return false;
    }
    let destinationBatchEmpty = gridData.filter(
      (item: any) => !item.destinationBatch
    );
    if (destinationBatchEmpty && destinationBatchEmpty.length > 0) {
      showAlert('Error', 'Please enter destination batch number');
      return false;
    }
    let transferQuantityEmpty = gridData.filter(
      (item: any) =>
        !item.transferQuantity ||
        (item.transferQuantity ? Number(item.transferQuantity) : 0) < 0
    );
    if (transferQuantityEmpty && transferQuantityEmpty.length > 0) {
      showAlert('Error', 'Please enter transfer quantity');
      return false;
    }

    if (
      !isNaN(Number(item.requiredQuantity)) &&
      Number(item.requiredQuantity) !== getTransferQuantity() &&
      props?.document !== DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      showAlert(
        'Error',
        'Transfer quantity can not be more or less than required quantity'
      );
      return false;
    } else if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      !isNaN(Number(item.requiredQuantity)) &&
      Number(item.requiredQuantity) < getTransferQuantity() &&
      !Utility.isJWOReceiveDispatchAdditionalQtyEnable()
    ) {
      showAlert('Error', t('JOB_WORK_OUT.ERROR_TRANSFER_REQ_QTY'));
      return false;
    } else if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      !isNaN(Number(item.requiredQuantity)) &&
      Number(item.requiredQuantity) > getTransferQuantity()
    ) {
      showAlert(
        'Error',
        t('Transfer quantity cannot be less than required quantity')
      );
      return false;
    }

    let extraTransferQuantityThenAvailableQtyRows = gridData.filter(
      (data: any) => +data.transferQuantity > +data.batchSize
    );

    if (extraTransferQuantityThenAvailableQtyRows.length) {
      showAlert(
        'Error',
        'Transfer quantity can not be more available quantity'
      );
      return false;
    }
    if (props.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      let extraTransferQuantityThenAvailableSharedQtyRows = gridData.filter(
        (data: any) => +data.transferQuantity > sharedAvailableQty
      );
      if (extraTransferQuantityThenAvailableSharedQtyRows.length) {
        showAlert(
          'Error',
          'Transfer quantity cannot be more than available quantity. Please check with other shared transfer quantity.'
        );
        return false;
      }
    }
    let duplicateSerial = gridData.map((data: any) => {
      return data.invalidFields;
    });
    let hasError = false;
    if (duplicateSerial && duplicateSerial.length > 0) {
      duplicateSerial.forEach((data: any) => {
        if (data.includes('serialBatchNumber')) {
          hasError = true;
        }
      });
    }
    if (hasError) {
      showAlert(
        'Error',
        'Please remove duplicated batch number for selected warehouse'
      );
      return false;
    }

    let wareHouseData = allWarehouseData?.content?.find(
      (warehouse: any) => warehouse?.code === sourceWarehouse.code
    );
    if (
      (!Utility.isEmpty(wareHouseData?.warehouseRowInfos) &&
        Utility.isEmpty(sourceRRB?.srcRowCode)) ||
      (!Utility.isEmpty(wareHouseData?.warehouseRackInfos) &&
        Utility.isEmpty(sourceRRB?.srcRackCode)) ||
      (!Utility.isEmpty(wareHouseData?.warehouseBinInfos) &&
        Utility.isEmpty(sourceRRB?.srcBinCode))
    ) {
      showAlert(
        'Warning!',
        'You have selected a warehouse that needs Row, Rack, Bin information selected. Please fill the data.'
      );
      return false;
    }
    if (
      props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      selectProductJWOList &&
      selectProductJWOList.length !== 0
    ) {
      const isExist = selectProductJWOList.find(
        (availSerial: any) =>
          availSerial.warehouseCode === destinationWarehouse.code
      );
      if (!isExist) {
        showAlert('Warning!', t('JOB_WORK_OUT.SAME_WAREHOUSE_ERROR'));
        return false;
      }
    }

    return true;
  };

  const getProductAvailableQuantity = (product: any) => {
    let reserveQty = 0,
      availableQty = 0;
    let whData = props?.selectedWarehouseProductsData;
    whData?.forEach((ele: any) => {
      reserveQty += ele.productReservedQuantity[product?.pid]
        ? ele.productReservedQuantity[product?.pid]
        : 0;
      availableQty += ele.productAvailableQuantity[product?.pid]
        ? ele.productAvailableQuantity[product?.pid]
        : 0;
    });
    const availableQuantity = availableQty - reserveQty;
    return Utility.roundingOff(availableQuantity, QTY_ROUNDOFF_PRECISION);
  };

  const getTransferQuantity = () => {
    let x = gridData.map((data: any) => Number(data.transferQuantity));
    let total = x.reduce((x: any, a: any) => x + a, 0);
    return isNaN(total)
      ? 0
      : Utility.roundingOff(total, QTY_ROUNDOFF_PRECISION);
  };

  const onStockTransferSave = () => {
    let advancedData: any[] = [];
    gridData?.forEach((data: any) => {
      let d = {
        qtyToTransfer: data.transferQuantity,
        sourceSerialBatchNumber: data.serialBatchNumber?.serialBatchNumber,
        manufacturingDate: DateFormatService.getFormattedDateString(
          data.serialBatchNumber?.manufacturingDate,
          tenantInfo.dateFormat,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        expiryDate: DateFormatService.getFormattedDateString(
          data.serialBatchNumber?.expiryDate,
          tenantInfo.dateFormat,
          BOOKS_DATE_FORMAT['DD-MM-YYYY']
        ),
        destSerialBatchNumber:
          data?.destinationBatch?.serialBatchNumber || data?.destinationBatch,
        qtyAvailable: data.batchSize,
        customField: getLineItemCFs(data)
      };
      advancedData.push(d);
    });
    if (validateDetail()) {
      let totalTransferQty = 0;
      totalTransferQty = getTransferQuantity();
      let warehouseDetail = {
        destBinCode: destinationRRB.destBinCode ?? null,
        destRackCode: destinationRRB.destRackCode ?? null,
        destRowCode: destinationRRB.destRowCode ?? null,
        srcBinCode: sourceRRB?.srcBinCode ?? null,
        srcRackCode: sourceRRB?.srcRackCode ?? null,
        srcRowCode: sourceRRB?.srcRowCode ?? null,
        srcWarehouseCode: sourceWarehouse.code,
        destWarehouseCode: destinationWarehouse.code,
        quantity: totalTransferQty,
        advancedTrackingType: TRACKING_TYPE.BATCH,
        advancedTrackingData: advancedData,
        productCode: item?.documentSequenceCode
      };
      let data: any = {};
      data['stockTransferWarehouseInventoryData'] = warehouseDetail;

      props.onSave(data);
    }
  };

  const getDestinationWarehouse = () => {
    if (!Utility.isEmpty(productWarehouse)) {
      let warehouseList = allWarehouseData?.content?.filter(
        (warehouse: any) =>
          warehouse.warehouseType !== 'REJECTED' && warehouse.active
      );
      //   let warehouseList = allWarehouseData?.content?.filter(
      //     (warehouse: any) =>
      //       warehouse.id !==
      //         (sourceWarehouse && sourceWarehouse.id ? sourceWarehouse.id : '') &&
      //       warehouse.warehouseType !== 'REJECTED' &&
      //       warehouse.active
      //   );
      if (!Utility.isEmpty(allWarehouseData?.content)) {
        let findJWOWarehouse = allWarehouseData?.content?.find(
          (warehouse: any) =>
            warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        findJWOWarehouse = Utility.isEmpty(findJWOWarehouse)
          ? jwoWarehouseFetched?.content?.[0]
          : findJWOWarehouse;

        let checkExistingJWOWarehouseIndex = warehouseList?.findIndex(
          (productWarehouse: any) =>
            productWarehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        if (
          !Utility.isEmpty(findJWOWarehouse) &&
          checkExistingJWOWarehouseIndex === -1 &&
          props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          warehouseList.push(findJWOWarehouse);
        }
      }
      return warehouseList && warehouseList.length > 0 ? warehouseList : [];
    } else {
      return [];
    }
  };

  const getJWDestinationWarehouse = () => {
    if (!Utility.isEmpty(productWarehouse)) {
      let warehouseList = productInventoryWarehouse?.filter(
        (warehouse: any) =>
          warehouse.id !==
          (sourceWarehouse && sourceWarehouse.id ? sourceWarehouse.id : '')
      );
      if (!Utility.isEmpty(allWarehouseData?.content)) {
        let findJWOWarehouse = allWarehouseData?.content?.find(
          (warehouse: any) =>
            warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        findJWOWarehouse = Utility.isEmpty(findJWOWarehouse)
          ? jwoWarehouseFetched?.content?.[0]
          : findJWOWarehouse;
        let checkExistingJWOWarehouseIndex = warehouseList?.findIndex(
          (productWarehouse: any) =>
            productWarehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
        );
        if (
          !Utility.isEmpty(findJWOWarehouse) &&
          checkExistingJWOWarehouseIndex === -1 &&
          props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER
        ) {
          warehouseList.push(findJWOWarehouse);
        }
      }
      if (warehouseList && warehouseList.length > 0) {
        if (props?.document === DOC_TYPE.JOB_WORK_OUT_ORDER) {
          let jwoWarehouse = warehouseList?.find(
            (warehouse: any) =>
              warehouse.warehouseType === DOCUMENT_TYPE.JOB_WORK_OUT
          );
          if (!Utility.isEmpty(jwoWarehouse)) {
            setDestinationWarehouse(jwoWarehouse);
          }
        }
      }
    }
  };

  const addProductCustomFieldsToLineItem = (lineItem: any, product?: any) => {
    let cfs: any[] = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      // Set default values of CFs when new line is added
      lineItem?.customField?.forEach((productCF: any) => {
        const filteredCF = batchSerialCFfromStore?.content?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DROPDOWN.toLowerCase()
            ) {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.value === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
      if (Utility.isEmpty(lineItem?.customField)) {
        batchSerialCFfromStore?.content.forEach((ele: any) => {
          lineItem[ele.id] = '';
        });
      }
    }
    return { ...lineItem, customField: cfs };
  };
  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = gridColumns;
    let colConfigsWithOnlyCF = oldColConfigs?.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = batchSerialCFfromStore?.content.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              new Date(lineItem[cf.id]),
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'BATCHSERIAL',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }
    return newCfs;
  };

  const getTitleAndValueField = (
    title: any,
    value: any,
    isRightAlign = false
  ) => {
    return (
      <div
        className={`column parent-width gap-1 ${
          isRightAlign ? ' align-items-end' : ''
        }`}
      >
        <DKLabel text={title} className={`fs-r font-medium text-blue `} />
        <DKLabel text={value} className={`fs-r `} />
      </div>
    );
  };

  const getHeaderSection = () => {
    if (moduleName === MODULE_TYPE.SELL) {
      return (
        <>
          <div className="row justify-content-between align-items-start">
            {getTitleAndValueField('Product Name', item?.name)}
            {getTitleAndValueField('Product Code', item?.documentSequenceCode)}
            {getTitleAndValueField(
              'Available Quantity',
              getProductAvailableQuantity(item),
              true
            )}
            {!isNaN(item.requiredQuantity) &&
              getTitleAndValueField(
                'Required Quantity',
                item.requiredQuantity,
                true
              )}
            {getTitleAndValueField(
              'Transfer Quantity',
              getTransferQuantity(),
              true
            )}
          </div>
          <div className="row ">
            {warehouseData &&
              warehouseData.map((item: any, i: any) => {
                return (
                  <div className="column pt-3 gap-3 parent-width">
                    <div className="row parent-width mt-3">
                      <SourceDestinationWarehouseManagementRRB
                        type={ADVANCE_TRACKING.BATCH}
                        document={props?.document ?? ''}
                        sourceWarehouseData={getActiveWarehouses()}
                        targetWarehouseData={getDestinationWarehouse()}
                        sourceWarehouse={sourceWarehouse}
                        destinationWarehouse={destinationWarehouse}
                        onSave={(data: any) => {
                          setSourceRRB({
                            ...sourceRRB,
                            srcRowCode: data?.srcRowCode ?? null,
                            srcRackCode: data?.srcRackCode ?? null,
                            srcBinCode: data?.srcBinCode ?? null
                          });
                          setDestinationRRB({
                            ...destinationRRB,
                            destRowCode: data?.destRowCode ?? null,
                            destRackCode: data?.destRackCode ?? null,
                            destBinCode: data?.destBinCode ?? null
                          });
                        }}
                        onSourceWarehouseChange={(data: any) => {
                          if (data) {
                            let result = productWarehouse.find(
                              (warehouse: any) => warehouse.code === data.code
                            )?.advancedTrackingMeta;

                            let batchData = result?.filter(
                              (serial: any) =>
                                serial.batchSizeFulfilled < serial.batchSize
                            );
                            setAvailableBatchData(batchData);
                            setAvailableBatchDataNonFiltered(batchData);
                            setSourceWarehouse(data);
                            getDataGridColumns();
                            if (!readOnlyTarget) {
                              if (
                                props?.document !== DOC_TYPE.JOB_WORK_OUT_ORDER
                              ) {
                                setDestinationWarehouse({});
                              }
                            }
                            let tmpData = [...gridData];
                            tmpData = tmpData.map((ele: any) => {
                              return { ...ele, serialBatchNumber: '' };
                            });
                            let initialGridData = [
                              {
                                warehouseCode: '',
                                serialBatchNumber: '',
                                manufacturingDate: '',
                                expiryDate: '',
                                batchSize: 0,
                                batchSizeFulfilled: 0,
                                invalidFields: [
                                  'warehouseCode',
                                  'serialBatchNumber'
                                ] //
                              }
                            ];
                            setGridData(initialGridData);
                            getDataGridColumns();
                            setDestinationBatchData([]);
                          }
                        }}
                        onTargetWarehouseChange={(data: any) => {
                          if (data) {
                            setDestinationWarehouse(data);
                            let result = productWarehouse?.find(
                              (warehouse: any) => warehouse.code === data.code
                            )?.advancedTrackingMeta;
                            if (!Utility.isEmpty(result)) {
                              setDestinationBatchData(result);
                            } else {
                              setDestinationBatchData([]);
                            }
                          }
                        }}
                        onRRBInfoChange={(data: any) => {
                          let tmpData = [...gridData];
                          tmpData = tmpData.map((ele: any) => {
                            return {
                              ...ele,
                              serialBatchNumber: '',
                              batchSize: 0,
                              manufacturingDate: '',
                              expiryDate: '',
                              destinationBatch: '',
                              transferQuantity: 0
                            };
                          });
                          setGridData(tmpData);
                        }}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      );
    }
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 1100,
          width: '100%',
          maxHeight: '95%',
          padding: 0
        }}
      >
        {getHeader()}
        <div className="column parent-size p-5">
          {getHeaderSection()}
          <div className="column mt-3 parent-width">
            {getBatchTrackingGrid()}
          </div>
          <div className="">
            <DKButton
              title={`+ ${t('DOCUMENT.ADD_ITEM')}`}
              onClick={() => addNewItem()}
              className={`text-blue fw-m p-0`}
              style={{ marginTop: -10, zIndex: 1 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockTransferAdvancedBatchTrackingPopup;
