import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import {
  selectBatchSerialCustomFields,
  selectCustomFields,
  selectUOMs
} from '../../Redux/Slices/CommonDataSlice';
import Utility from '../../Utility/Utility';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  MODULE_TYPE,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION,
  STATUS_TYPE,
  TRACKING_TYPE
} from '../../Constants/Constant';
import { selectWarehouseProductByID } from '../../Redux/Slices/WarehouseProductSlice';
import {
  COLUMN_CONFIGS_FOR_FULFILLMENT,
  COLUMN_CONFIGS_FOR_RG
} from '../../Components/Mrp/Constants/MRPColumnConfigs';
import {
  DKButton,
  DKCalendar,
  DKIcon,
  DKIcons,
  DKLabel,
  removeLoader,
  showAlert,
  showLoader,
  showToast,
  DKLine,
  DKListPicker2,
  DKDataGrid,
  DKInput,
  INPUT_TYPE,
  DKTooltipWrapper
} from 'deskera-ui-library';
import ic_barcode_green from '../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../Assets/Icons/ic_barcode_red.svg';
import ic_bom_allocate_red from '../../Assets/Icons/ic_bom_allocate_red.svg';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import { fetchBatchTrackingProducts } from '../../Redux/Slices/BatchTrackingSlice';
import BatchTrackingAssignment from '../AdvancedTrackingPopup/BatchTrackingAssignment';
import NoneTrackedAssignment from '../WarehouseManagement/NoneTrackedAssignment';
import { FulfillmentItemState } from '../../Models/Fulfillment';
import { getNewColumn } from '../../Components/Accounting/JournalEntry/JEHelper';
import ExpandedFulfillmentTable from './ExpandedFulfillmentTable';
import BatchTrackingReceive from '../AdvancedTrackingPopup/BatchTrackingReceive';
import SerialTrackedAssignment from '../AdvancedTrackingPopup/SerialTrackedAssignment';
import { fetchSerialTrackingProducts } from '../../Redux/Slices/SerialTrackingSlice';
import NoneTrackedReceive from '../WarehouseManagement/NoneTrackedReceive';
import SerialTrackedReceive from '../AdvancedTrackingPopup/SerialTrackedReceive';
import ic_warning_red from '../../Assets/Icons/ic_warning_red.png';
import DateFormatService from '../../Services/DateFormat';
import StockTransferAdvancedSerialTracking from '../../Components/StockManagement/StockTransfer/StockTransferAdvancedSerialTrackingPopup';
import ProductService from '../../Services/Product';
import WorkoutService from '../../Services/Workout';
import ReceiveJWOProduct from '../../Components/StockManagement/StockTransfer/ReceiveJWOProduct';
import MRPProductsService from '../../Services/MRP/MRPProducts';

const FulfillmentGrid = (props: any) => {
  const uoms = useAppSelector(selectUOMs);
  const [showBatchTracking, setShowBatchTracking] = useState(false);
  const [showSerialTracking, setShowSerialTracking] = useState(false);
  const [showMultipleWarehouse, setShowMultipleWarehouse] = useState(false);
  const [showGoodsReceiveProductPopup, setShowGoodsReceiveProductPopup] =
    useState(false);
  const [stockTransferItems, setStockTransferItems] = useState();
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [multipleWarehouseData, setMultipleWarehouseData] = useState<any[]>([]);
  const [batchMultipleWarehouseData, setBatchMultipleWarehouseData] = useState<
    any[]
  >([]);
  const [serialMultipleWarehouseData, setSerialMultipleWarehouseData] =
    useState<any[]>([]);
  const [selectedFulfillmentItem, setSelectedFulfillmentItem] = useState<any>(
    {}
  );
  const dispatch = useAppDispatch();
  const [gridRowData, setGridRowData] = useState<any>([]);
  const localWarehouse = useAppSelector(selectWarehouseProductByID);
  const [columnConfig, setColumnConfig] = useState<any>(
    props.module === DOC_TYPE.FULFILLMENT
      ? COLUMN_CONFIGS_FOR_FULFILLMENT
      : COLUMN_CONFIGS_FOR_RG
  );
  const [selectedItemId, setSelectedItemId] = useState<any>(null);
  const [defaultWarehouse, setdefaultWarehouse] = useState(
    props.defaultWarehouse
  );
  const [pendingQuantity, setPendingQuantity] = useState<any>([]);

  const [quantityToFulfill, setQuantityToFulfill] = useState<any>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>([]);
  const [localQuantityToFulfill, setLocalQuantityToFulfill] = useState<any>([]);
  const [rawMaterialToConsume, setRawMaterialToConsume] = useState<any>([]);
  const [fulfillmentItems, setFulfillmentItems] = useState<any>(
    props.fulfillmentItems.map((items: any) => {
      return {
        ...items,
        invalidFields: items.invalidFields ? items.invalidFields : []
      };
    })
  );
  const isDropShip = props.isDropShip || false;
  const [allotedAdvancedTrackingData, setAllotedAdvancedTrackingData] =
    useState(props?.allotedAdvancedTrackingData);
  const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const [disableQty, setDisableQty] = useState(false); // use it to disable committing qty
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);

  const getSelectedItemIndex = (id: any = null) => {
    return id || selectedItemId
      ? fulfillmentItems.findIndex(
          (item: any) => item.id === (id || selectedItemId)
        )
      : -1;
  };
  const selectedIndex = getSelectedItemIndex();

  useEffect(() => {
    setGridRowData(getRowData());
    populateQtyArr();
  }, []);
  useEffect(() => {}, [multipleWarehouseData]);

  useEffect(() => {
    setFulfillmentItems(
      props.fulfillmentItems.map((items: any) => {
        return {
          ...items,
          invalidFields: items.invalidFields ? items.invalidFields : []
        };
      })
    );
  }, [props.fulfillmentItems]);

  useEffect(() => {
    //initial
    let tmpWarehouse = [...selectedWarehouse];
    let tmpQuantityToFulfill = [...quantityToFulfill];
    let pendingItemQuantity = [...pendingQuantity];

    let productAvailableQuantityMap = new Map();

    if (props.module === DOC_TYPE.FULFILLMENT) {
      for (const item of props.fulfillmentItems) {
        if (item.productCode) {
          // Find all products in warehouseProduct with matching productCode
          const warehouseProducts = props?.warehouseProduct?.filter(
            (wp: any) =>
              wp.productAvailableQuantity?.[item.productCode] !== undefined
          );

          if (warehouseProducts.length > 0) {
            let totalAvailableQuantity = 0;

            warehouseProducts.forEach((warehouseProduct: any) => {
              const reservedQuantity =
                warehouseProduct.productReservedQuantity[item.productCode] || 0;
              const availableQuantity =
                warehouseProduct.productAvailableQuantity[item.productCode] -
                reservedQuantity;

              totalAvailableQuantity += availableQuantity;
            });

            if (!productAvailableQuantityMap.has(item.productCode)) {
              productAvailableQuantityMap.set(
                item.productCode,
                totalAvailableQuantity
              );
            }
          }
        }
      }
    }

    if (
      props.warehouseProduct &&
      props.warehouseProduct.length &&
      props.fulfillmentItems &&
      props.completeProcess
    ) {
      props.fulfillmentItems.forEach((item: any, index: any) => {
        const { pendingQty, qtyToFulfill } = getPendingandRequiredQty(item);
        if (Utility.isEmpty(tmpWarehouse[index]) && item.warehouseCode) {
          let warehouseWithProduct: any = [];
          if (props.module === DOC_TYPE.GOODS_RECEIPT) {
            warehouseWithProduct = [...props?.warehouseProduct];
          } else {
            warehouseWithProduct = props?.warehouseProduct?.filter(
              (war: any) =>
                war?.productAvailableQuantity?.[item.productCode] > 0
            );
          }
          let foundWarehouse = warehouseWithProduct?.find(
            (warehouseItem: any) => warehouseItem?.code === item.warehouseCode
          );
          if (!Utility.isEmpty(foundWarehouse)) {
            tmpWarehouse[index] = item.warehouseCode;
          } else {
            let primaryWarehouse = warehouseWithProduct?.find(
              (warehouseItem: any) => warehouseItem?.primary
            );
            if (!Utility.isEmpty(primaryWarehouse)) {
              tmpWarehouse[index] = primaryWarehouse.code;
            } else {
              tmpWarehouse[index] = warehouseWithProduct?.[0]?.code;
            }
          }
        }
        if (props.module === DOC_TYPE.GOODS_RECEIPT) {
          if (tmpQuantityToFulfill[index] === undefined) {
            let qty = item.documentUOMSchemaDefinition
              ? item.uomQuantityRequired ||
                Utility.uomPendingToBeUomReceivedQuantity(item)
              : item.quantityRequired ||
                Utility.pendingToBeReceivedQuantity(item);
            tmpQuantityToFulfill[index] = qty;
            pendingItemQuantity[index] = qty;
          }
        } else {
          // Case for set remaining qty
          // case for set qty to 0
          // case for just set normally
          if (tmpQuantityToFulfill[index] === undefined) {
            let availableQty =
              productAvailableQuantityMap.get(item.productCode) || 0;
            if (availableQty === 0) {
              // Case 2: Set quantity to 0 if the available quantity is 0
              tmpQuantityToFulfill[index] = 0;
              let updatedItem = {
                ...item,
                fulfilledQuantity: 0
              };
              if (props.onFulfillmentItemUpdated) {
                props.onFulfillmentItemUpdated(updatedItem);
              }
            } else if (availableQty < qtyToFulfill) {
              // Case 1: Set remaining quantity if available quantity is less than qtyToFulfill
              tmpQuantityToFulfill[index] = availableQty;
              productAvailableQuantityMap.set(item.productCode, 0);
              let updatedItem = {
                ...item,
                fulfilledQuantity: availableQty
              };
              if (props.onFulfillmentItemUpdated) {
                props.onFulfillmentItemUpdated(updatedItem);
              }
            } else {
              // Case 3: Set quantity normally if there is enough available quantity
              tmpQuantityToFulfill[index] = qtyToFulfill;
              productAvailableQuantityMap.set(
                item.productCode,
                availableQty - qtyToFulfill
              );
            }
          }
          if (pendingItemQuantity[index] === undefined) {
            pendingItemQuantity[index] = pendingQty;
          }
        }
      });
    }

    setSelectedWarehouse(tmpWarehouse);
    setQuantityToFulfill(tmpQuantityToFulfill);
    setPendingQuantity(pendingItemQuantity);
  }, [props.warehouseProduct, props.fulfillmentItems, props.completeProcess]);

  useEffect(() => {
    setGridRowData(getRowData());
  }, [
    props.warehouseProduct,
    props.fulfillmentItems,
    quantityToFulfill,
    selectedWarehouse
  ]);

  useEffect(() => {
    // populateQtyArr();
  }, [props.warehouseProduct, props.fulfillmentItems]);

  useEffect(() => {
    if (selectedIndex == -1) {
      return;
    }
    const updatedPendingQty = [...pendingQuantity];
    const updatedQtyToFulfill = [...quantityToFulfill];
    const updatedlocalQuantityToFulfill = [...localQuantityToFulfill];
    let qtyToFulfill;
    let pendingQty = Utility.getPendingQuantityForPopup(
      fulfillmentItems[selectedIndex],
      true
    );

    let availableQuantity = Utility.getAvailableQuantity(
      fulfillmentItems[selectedIndex]
    );

    if (fulfillmentItems[selectedIndex].type === PRODUCT_TYPE.NON_TRACKED) {
      qtyToFulfill = pendingQty;
    } else {
      qtyToFulfill =
        pendingQty > availableQuantity
          ? availableQuantity < 0
            ? pendingQty
            : availableQuantity
          : pendingQty;
    }

    updatedPendingQty[selectedIndex] = pendingQty;

    if (isDropShip) {
      updatedQtyToFulfill[selectedIndex] = pendingQty;
      //setQuantityToFulfill(pendingQty);
    } else {
      updatedQtyToFulfill[selectedIndex] = qtyToFulfill;
      //setQuantityToFulfill(qtyToFulfill);
    }
    updatedlocalQuantityToFulfill[selectedIndex] = qtyToFulfill;

    if (props.module === DOC_TYPE.GOODS_RECEIPT) {
      let qty = fulfillmentItems[selectedIndex].documentUOMSchemaDefinition
        ? fulfillmentItems[selectedIndex].uomQuantityRequired ||
          Utility.uomPendingToBeUomReceivedQuantity(
            fulfillmentItems[selectedIndex]
          )
        : fulfillmentItems[selectedIndex].quantityRequired ||
          Utility.pendingToBeReceivedQuantity(fulfillmentItems[selectedIndex]);
      updatedPendingQty[selectedIndex] = qty;
      updatedQtyToFulfill[selectedIndex] = qty;
    }

    setPendingQuantity(updatedPendingQty);
    setQuantityToFulfill(updatedQtyToFulfill);
    setLocalQuantityToFulfill(updatedlocalQuantityToFulfill);
  }, [localWarehouse, selectedItemId]);

  useEffect(() => {
    updateConfig(fulfillmentItems[selectedIndex]);
    if (props.onFulfillmentItemUpdated) {
      props.onFulfillmentItemUpdated(selectedFulfillmentItem);
    }
    if (props.onItemsUpdated) {
      props.onItemsUpdated(selectedFulfillmentItem);
    }
  }, [localWarehouse, selectedFulfillmentItem]);

  useEffect(() => {
    let newCols = [...columnConfig];
    let productCustomFields = selectCustomFieldsData?.content?.filter(
      (item: any) => {
        return (
          item.modules?.includes('PRODUCT') &&
          item.status === STATUS_TYPE.ACTIVE
        );
      }
    );
    if (
      props.parentDocumentType &&
      props.parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      !columnConfig.find(
        (col: any) => col.id === 'productQtyForWhichRmDispatched'
      )
    ) {
      const dispatchedQtyColumn = getNewColumn({
        id: 'productQtyForWhichRmDispatched',
        label: 'Dispatched Qty',
        fieldType: INPUT_TYPE.TEXT
      });
      dispatchedQtyColumn.editable = false;
      dispatchedQtyColumn.width = 150;
      newCols.push(dispatchedQtyColumn);
    }

    productCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      const newItemInExistingColConfig = newCols.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        newCols.push({ ...newItem });
      }
    });
    newCols = newCols.filter((col: any) => !col.hidden);
    setColumnConfig([...newCols]);

    let tempState = [...fulfillmentItems];
    fulfillmentItems.forEach((item: any, index: any) => {
      if (!Utility.isEmpty(productCustomFields)) {
        // Set default values of CFs when new line is added

        item.customField?.forEach((productCF: any, cfIndex: any) => {
          const filteredCF = productCustomFields?.find(
            (field: any) =>
              field.code === productCF.code &&
              field.status === STATUS_TYPE.ACTIVE
          );

          if (filteredCF) {
            let cfToUpdate = {
              id: filteredCF.id,
              shortName: filteredCF.shortName,
              module: filteredCF.module,
              code: filteredCF.code,
              label: filteredCF.label,
              value: ''
            };
            let valueOfCF = '';
            if (
              typeof productCF.value !== 'undefined' &&
              productCF.value !== null &&
              productCF.value !== ''
            ) {
              if (
                filteredCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
              ) {
                tempState[index][filteredCF.id] =
                  DateFormatService.getDateFromStr(
                    productCF.value,
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  );
              } else if (filteredCF.fieldType.toLowerCase() === 'user') {
                const tempCF = filteredCF?.attributes?.find(
                  (attr: any) => attr.code === productCF.value
                );
                if (tempCF) {
                  tempState[index][filteredCF.id] = tempCF;
                }
              } else if (
                filteredCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DROPDOWN.toLowerCase()
              ) {
                const tempCF = filteredCF?.attributes?.find(
                  (attr: any) => attr.value === productCF.value
                );
                if (tempCF) {
                  tempState[index][filteredCF.id] = tempCF;
                }
              } else {
                tempState[index][filteredCF.id] = productCF.value;
              }
              // valueOfCF = productCF.value;
            } else {
              tempState[index][filteredCF.id] = '';
              if (filteredCF.mandatory) {
                tempState[index]['invalidFields'].push(filteredCF.id);
              }
            }

            // cfToUpdate.value = valueOfCF;
            // cfs.push(cfToUpdate);
          }
        });
      }
    });
    props.updateFulfillmentData(tempState, newCols);
  }, [selectCustomFieldsData]);

  const populateQtyArr = () => {
    const updatedPendingQty = [...pendingQuantity];
    const updatedQtyToFulfill = [...quantityToFulfill];

    const updatedlocalQuantityToFulfill = [...localQuantityToFulfill];
    fulfillmentItems.forEach((item: any, index: any) => {
      let qtyToFulfill;
      let pendingQty = Utility.getPendingQuantityForPopup(item, true);
      let availableQuantity = Utility.getAvailableQuantity(item);
      if (item.type === PRODUCT_TYPE.NON_TRACKED) {
        qtyToFulfill = pendingQty;
      } else {
        qtyToFulfill =
          pendingQty > availableQuantity
            ? availableQuantity < 0
              ? pendingQty
              : availableQuantity
            : pendingQty;
      }
      updatedPendingQty[index] = pendingQty;
      //setPendingQuantity(pendingQty);
      if (isDropShip) {
        updatedQtyToFulfill[index] = pendingQty;
        // setQuantityToFulfill(pendingQty);
      } else {
        updatedQtyToFulfill[index] = qtyToFulfill;
        if (item.fulfilledQuantity > 0) {
          // if already fulfilled then take that value instead of old
          updatedQtyToFulfill[index] = item.fulfilledQuantity;
        }
        if (item.receiptQuantity > 0) {
          updatedQtyToFulfill[index] = item.receiptQuantity; // RG case
        }
      }
      if (props.module === DOC_TYPE.GOODS_RECEIPT) {
        let qty = item.documentUOMSchemaDefinition
          ? item.uomQuantityRequired ||
            Utility.uomPendingToBeUomReceivedQuantity(item)
          : item.quantityRequired || Utility.pendingToBeReceivedQuantity(item);
        updatedPendingQty[index] = qty;
        updatedQtyToFulfill[index] = qty;
        if (item.receiptQuantity > 0) {
          updatedQtyToFulfill[index] = item.receiptQuantity; // RG case
        }
      }

      updatedlocalQuantityToFulfill[index] = qtyToFulfill;

      //setLocalQuantityToFulfill(qtyToFulfill);
    });
    setPendingQuantity(updatedPendingQty);
    setQuantityToFulfill(updatedQtyToFulfill);
    setLocalQuantityToFulfill(updatedlocalQuantityToFulfill);
  };

  const getButtonsForRow = (data: any, ind: any) => {
    let buttons: any[] = [];
    // TODO: handle delete of new line
    let index = fulfillmentItems.findIndex((item: any) => item.id == data.id);

    switch (data.advancedTracking) {
      case TRACKING_TYPE.BATCH:
        let totalItem =
          fulfillmentItems[index].advancedTrackingFulfilmentData &&
          fulfillmentItems[index].advancedTrackingFulfilmentData.length > 0 &&
          fulfillmentItems[index].advancedTrackingFulfilmentData.reduce(
            (a: any, b: any) => +a + +parseFloat(b.qtyToFulfil),
            0
          );
        totalItem = Utility.roundingOff(totalItem, QTY_ROUNDOFF_PRECISION);
        // uncomment for Batch tracking product UOM Schema ticket
        if (
          !Utility.isEmpty(fulfillmentItems[index].documentUOMSchemaDefinition)
        ) {
          totalItem = Utility.getUomQuantity(
            totalItem,
            fulfillmentItems[index].documentUOMSchemaDefinition
          );
        }
        if (props.module === DOC_TYPE.GOODS_RECEIPT) {
          totalItem =
            fulfillmentItems[index].advancedTrackingMetaData &&
            fulfillmentItems[index].advancedTrackingMetaData.length > 0 &&
            fulfillmentItems[index].advancedTrackingMetaData.reduce(
              (a: any, b: any) => +a + +parseFloat(b.batchSize),
              0
            );
        }

        buttons.push({
          icon:
            totalItem === quantityToFulfill[index] ||
            quantityToFulfill[index] === '0'
              ? ic_barcode_green
              : ic_barcode_red,
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: ({ rowData }: any) => {
            setSelectedItemId(rowData.id);
            setSelectedFulfillmentItem({
              ...fulfillmentItems[getSelectedItemIndex(rowData.id)],
              stockTransferDocumentSeqCodes:
                props?.parentDocumentDetails?.stockTransferDocumentSeqCodes
            });
            fulfillmentItems['stockTransferDocumentSeqCodes'] =
              props?.parentDocumentDetails?.stockTransferDocumentSeqCodes;
            setBatchTracking(
              fulfillmentItems[getSelectedItemIndex(rowData.id)]
            );
          }
        });

        break;
      case TRACKING_TYPE.NONE:
        let availableQty = fulfillmentItems[index].documentUOMSchemaDefinition
          ? fulfillmentItems[index]?.uomAvailableQuantity
          : fulfillmentItems[index].availableQty
          ? fulfillmentItems[index].availableQty
          : fulfillmentItems[index].availableQuantity;
        const errorMessage =
          'Allocate quantities from multiple warehouses. Total available quantity is (' +
          Utility.roundingOff(availableQty, QTY_ROUNDOFF_PRECISION) +
          ')';
        const totalQuantity =
          (fulfillmentItems[index].warehouseInventoryData &&
            fulfillmentItems[index].warehouseInventoryData.length > 0 &&
            fulfillmentItems[index].warehouseInventoryData.reduce(
              (a: any, b: any) => (a += parseFloat(b['quantity'])),
              0
            )) ||
          0;
        buttons.push({
          icon:
            (Number(quantityToFulfill[index]) !== Number(totalQuantity) ||
              totalQuantity <= 0) &&
            quantityToFulfill[index] !== '0'
              ? ic_barcode_red
              : ic_barcode_green, //ic_bom_allocate_red,
          className: 'p-v-0 text-blue underline grid-action-link-h-padding',
          onClick: ({ rowData }: any) => {
            if (
              props.parentDocumentType &&
              props.parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
              Utility.isBatchOrSerialAdvanceTracking(
                rowData?.product?.bomProductsConfiguration
              )
            ) {
              setSelectedItemId(rowData.id);
              setSelectedFulfillmentItem({
                ...fulfillmentItems[getSelectedItemIndex(rowData.id)],
                stockTransferDocumentSeqCodes:
                  props?.parentDocumentDetails?.stockTransferDocumentSeqCodes
              });
              setShowMultipleWarehouse(true);
            } else {
              setSelectedItemId(rowData.id);
              setSelectedFulfillmentItem({
                ...fulfillmentItems[getSelectedItemIndex(rowData.id)],
                stockTransferDocumentSeqCodes:
                  props?.parentDocumentDetails?.stockTransferDocumentSeqCodes
              });
              setShowMultipleWarehouse(true);
            }
          }
        });

        break;
      case TRACKING_TYPE.SERIAL:
        if (props.module !== DOC_TYPE.GOODS_RECEIPT) {
          buttons.push({
            icon:
              fulfillmentItems[index]?.isQuickCommit ||
              fulfillmentItems[index]?.advancedTrackingFulfilmentData
                ?.length ===
                (fulfillmentItems[index].documentUOMSchemaDefinition
                  ? Utility.getUomWarehouseQuantity(
                      quantityToFulfill[index],
                      fulfillmentItems[index].documentUOMSchemaDefinition
                    )
                  : quantityToFulfill[index]) ||
              quantityToFulfill[index] === '0'
                ? ic_barcode_green
                : ic_barcode_red, //ic_bom_allocate_red,
            className: 'p-v-0 text-blue underline grid-action-link-h-padding',
            onClick: ({ rowData }: any) => {
              let indexInOriginalArray = getSelectedItemIndex(rowData.id);
              setSelectedItemId(rowData.id);

              setSelectedFulfillmentItem(
                fulfillmentItems[indexInOriginalArray]
              );
              setSerialTracking(fulfillmentItems[indexInOriginalArray]);
            }
          });
        } else {
          buttons.push({
            icon:
              fulfillmentItems[index]?.advancedTrackingMetaData?.length ===
                (fulfillmentItems[index].documentUOMSchemaDefinition
                  ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                      quantityToFulfill[index],
                      fulfillmentItems[index].documentUOMSchemaDefinition
                    )
                  : quantityToFulfill[index]) ||
              quantityToFulfill[index] === '0'
                ? ic_barcode_green
                : ic_barcode_red,
            className: 'p-v-0 text-blue underline grid-action-link-h-padding',
            onClick: ({ rowData }: any) => {
              let indexInOriginalArray = getSelectedItemIndex(rowData.id);
              setSelectedItemId(rowData.id);
              setSelectedFulfillmentItem({
                ...fulfillmentItems[indexInOriginalArray],
                stockTransferDocumentSeqCodes:
                  props?.parentDocumentDetails?.stockTransferDocumentSeqCodes
              });
              fulfillmentItems['stockTransferDocumentSeqCodes'] =
                props?.parentDocumentDetails?.stockTransferDocumentSeqCodes;
              setSerialTracking(fulfillmentItems[indexInOriginalArray]);
            }
          });
        }

        break;

      default:
        break;
    }
    let item = fulfillmentItems[index];
    let filteredProduct = props.warehouseProduct?.find(
      (warehouse: any) =>
        warehouse.code ===
        (defaultWarehouse ? defaultWarehouse : item.warehouseCode)
    );
    let tempfulfillItem = { ...item };
    tempfulfillItem.availableProductQuantity =
      (!Utility.isEmpty(filteredProduct?.productAvailableQuantity) &&
        filteredProduct?.productAvailableQuantity[
          tempfulfillItem.productCode
        ]) ||
      tempfulfillItem.product.availableQuantity ||
      0;
    let availableQty = item.documentUOMSchemaDefinition
      ? item?.uomAvailableQuantity
      : Utility.getAvailableQuantity(tempfulfillItem);
    if (
      item.type === 'BILL_OF_MATERIALS' &&
      availableQty < Number(pendingQuantity[index])
    ) {
      buttons.push({
        icon: ic_bom_allocate_red, //ic_bom_allocate_red,
        className: 'p-v-0 text-blue underline grid-action-link-h-padding',
        onClick: ({ rowData }: any) => {
          setSelectedItemId(rowData.id);

          openBuildAssembly(
            fulfillmentItems[getSelectedItemIndex(rowData.id)].product
          );
        }
      });
    }

    return buttons;
  };

  const openBuildAssembly = (data: any) => {
    props.onChangeAssemblyProduct(data);
  };

  const setSerialTracking = (item: any) => {
    loadSerialTrackingProducts(item.productCode);
    setShowSerialTracking(true);
  };

  const loadSerialTrackingProducts = async (code: any) => {
    try {
      dispatch(
        fetchSerialTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const setBatchTracking = (item: any) => {
    loadBatchTrackingProducts(item.productCode);
  };

  const loadBatchTrackingProducts = async (code: any) => {
    showLoader();
    try {
      let isBatchSerialsCustomFieldsAvailable =
        batchSerialCFfromStore?.content?.filter(
          (ele: any) => ele.status === STATUS_TYPE.ACTIVE
        );
      const data = await dispatch(
        Utility.isEmpty(isBatchSerialsCustomFieldsAvailable) ||
          props.module === DOC_TYPE.FULFILLMENT
          ? fetchBatchTrackingProducts({
              productCode: code,
              enableQCWarehouse: false
            })
          : fetchBatchTrackingProducts({
              productCode: code,
              enableQCWarehouse: false,
              checkBatchSerialCF: true
            })
      );
      if (data) {
        removeLoader();
        setShowBatchTracking(true);
      }
    } catch (err) {
      removeLoader();
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const getDataGrid = () => {
    return (
      <DKDataGrid
        title=""
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={gridRowData.map((item: any, index: any) => {
          return {
            ...item,
            rowButtons: isDropShip ? [] : getButtonsForRow(item, index)
          };
        })}
        columns={[
          ...columnConfig,
          {
            key: 'actions',
            name: 'Actions',
            type: INPUT_TYPE.BUTTON,
            actionButtonOptions: [],
            width: 180
          }
        ]}
        onRowUpdate={onRowUpdate}
        onRowClick={({ columnData, rowIndex }: any) => {
          if (props.fulfillmentItems) {
          }
          updateConfig(props.fulfillmentItems[rowIndex]);
        }}
        allowExpand={props.module === DOC_TYPE.GOODS_RECEIPT ? false : true}
        onRowExpand={(data: any) => {
          const updatedGridData = [...gridRowData];
          updatedGridData[data.rowIndex].expanded =
            !updatedGridData[data.rowIndex].expanded;
          setGridRowData(updatedGridData);
        }}
      />
    );
  };

  const getSelectedUomValue = (data: any) => {
    const id = data.documentUom || data.product.stockUom;
    let filteredUOM =
      uoms && uoms.length > 0 ? uoms.find((uom: any) => uom.id === id) : null;
    return filteredUOM?.name || '';
  };

  const getPendingandRequiredQty = (fulfillmentItem: any) => {
    let qtyToFulfill;
    let pendingQty = Utility.getPendingQuantityForPopup(fulfillmentItem, true);
    let availableQuantity = Utility.getAvailableQuantity(fulfillmentItem);
    if (fulfillmentItem.type === PRODUCT_TYPE.NON_TRACKED) {
      qtyToFulfill = pendingQty;
    } else {
      qtyToFulfill =
        pendingQty > availableQuantity
          ? availableQuantity < 0
            ? pendingQty
            : availableQuantity
          : pendingQty;
    }
    return { pendingQty, qtyToFulfill };
  };

  const updateInventory = (
    value: any,
    type: any,
    availableQty: number,
    required: number,
    fulfillmentItem: any,
    rowIndex?: any,
    selectedIndex?: any
  ) => {
    let hasFulfillmentError;
    let inventory: any;

    if (fulfillmentItem.documentUOMSchemaDefinition) {
      inventory = {
        ...fulfillmentItem,
        uomFulfilledQuantity: value,
        advancedTrackingFulfilmentData: []
      };
    } else {
      inventory = {
        ...fulfillmentItem,
        fulfilledQuantity: value,
        quantityFulfilled: value,
        advancedTrackingFulfilmentData: []
      };
    }
    if (rowIndex != null) {
      updateComittingColumn(rowIndex, value);
    }
    const updatedQtyToFulfill = [...quantityToFulfill];
    updatedQtyToFulfill[selectedIndex] = value;
    setQuantityToFulfill(updatedQtyToFulfill); //a2a2
    setSelectedFulfillmentItem(inventory);

    if (type === PRODUCT_TYPE.NON_TRACKED) {
      return;
    }
    const commitedQty = parseFloat(value);

    if (commitedQty > availableQty && commitedQty > 0) {
      hasFulfillmentError = true;
    } else if (commitedQty > required && commitedQty > 0) {
      hasFulfillmentError = true;
    } else {
      hasFulfillmentError = false;
    }
    if (props.hasError) {
      props.hasError(hasFulfillmentError);
    }
  };

  const updateInventoryForRG = (value: any, index: any) => {
    let inventory;
    const receivedGoodsItem: any = fulfillmentItems[index];
    if (receivedGoodsItem.documentUOMSchemaDefinition) {
      inventory = {
        ...receivedGoodsItem,
        uomReceiptQuantity: value,
        receiptQuantity: value
      };
    } else {
      inventory = {
        ...receivedGoodsItem,
        receiptQuantity: value
      };
    }
    const updatedQtyToFulfill = [...quantityToFulfill];
    updatedQtyToFulfill[index] = value;
    setQuantityToFulfill(updatedQtyToFulfill);
    setSelectedFulfillmentItem(inventory);
  };

  const updateComittingColumn = (rowIndex: any, value: any) => {
    const updateGridData = [...gridRowData];

    updateGridData[rowIndex].committing = value;
    setGridRowData(updateGridData);
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    let indexInOriginalArray = fulfillmentItems.findIndex(
      (item: any) => item.id == rowData.id
    );
    setSelectedItemId(rowData.id);
    const updatedQtyToFulfill = [...quantityToFulfill];

    const selectedFulfillmentItem = fulfillmentItems[indexInOriginalArray];
    let updatedRowData = [...gridRowData];
    switch (columnKey) {
      case 'warehouse':
        let tmpSelectedWarehouse = [...selectedWarehouse];
        tmpSelectedWarehouse[indexInOriginalArray] = rowData.warehouse.value;
        setSelectedWarehouse(tmpSelectedWarehouse);
        let copyFulfillmentItems = [...fulfillmentItems];
        copyFulfillmentItems[indexInOriginalArray].warehouseCode =
          rowData.warehouse.value;
        copyFulfillmentItems[indexInOriginalArray].product.warehouseCode =
          rowData.warehouse.value;
        props.updateFulfillmentData(copyFulfillmentItems, columnConfig);
        // setGridRowData(updatedRowData);
        break;
      case 'committing':
        let filteredProduct = props.warehouseProduct?.find(
          (warehouse: any) =>
            warehouse.code ===
            (defaultWarehouse
              ? defaultWarehouse
              : selectedFulfillmentItem.warehouseCode)
        );
        let tempfulfillItem = { ...selectedFulfillmentItem };
        tempfulfillItem.availableProductQuantity =
          (!Utility.isEmpty(filteredProduct?.productAvailableQuantity) &&
            filteredProduct?.productAvailableQuantity[
              tempfulfillItem.productCode
            ]) ||
          tempfulfillItem.product.availableQuantity ||
          0;
        let availableQty = selectedFulfillmentItem.documentUOMSchemaDefinition
          ? selectedFulfillmentItem?.uomAvailableQuantity
          : Utility.getAvailableQuantity(tempfulfillItem);

        if (Utility.isToleranceSettingsEnabled()) {
          const updatedQuantity = Utility.roundingOff(
            rowData.committing,
            QTY_ROUNDOFF_PRECISION
          );
          // isBlur
          //   ? Utility.roundingOff(rowData.committing, QTY_ROUNDOFF_PRECISION)
          //   : event;
          const fulfilledQuantity =
            selectedFulfillmentItem.documentUOMSchemaDefinition
              ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                  parseFloat(updatedQuantity.toString()),
                  selectedFulfillmentItem?.documentUOMSchemaDefinition
                )
              : updatedQuantity;
          let inventory = {
            ...selectedFulfillmentItem,
            quantityFulfilled: parseFloat(fulfilledQuantity),
            fulfilledQuantity: parseFloat(fulfilledQuantity)
          };
          if (selectedFulfillmentItem.documentUOMSchemaDefinition) {
            inventory.uomFulfilledQuantity = updatedQuantity;
          }
          updateComittingColumn(rowIndex, updatedQuantity);

          updatedQtyToFulfill[indexInOriginalArray] = updatedQuantity;
          setQuantityToFulfill(updatedQtyToFulfill);
          setSelectedFulfillmentItem(inventory);
        } else {
          updateComittingColumn(rowIndex, rowData.committing);

          updatedQtyToFulfill[indexInOriginalArray] = rowData.committing;
          updatedQtyToFulfill[indexInOriginalArray] = rowData.committing;
          setQuantityToFulfill(updatedQtyToFulfill);
          updateInventory(
            rowData.committing,
            selectedFulfillmentItem?.type,
            availableQty,
            pendingQuantity[indexInOriginalArray],
            selectedFulfillmentItem,
            rowIndex,
            indexInOriginalArray
          );
        }
        if (
          !isDropShip &&
          parseFloat(rowData.committing) > 0 &&
          parseFloat(rowData.committing) > availableQty &&
          tenantInfo?.allowNegativeInventory &&
          selectedFulfillmentItem?.type === PRODUCT_TYPE.TRACKED
        ) {
          setDisableQty(true);
          let buttons = [
            {
              title: 'No',
              className: 'bg-gray2 border-m ',
              onClick: () => {
                const updatedQtyToFulfill = [...quantityToFulfill];

                setDisableQty(false);

                updatedQtyToFulfill[indexInOriginalArray] = pendingQuantity;
                setQuantityToFulfill(updatedQtyToFulfill);
                updateInventory(
                  pendingQuantity[indexInOriginalArray], //aiai
                  selectedFulfillmentItem.type,
                  availableQty,
                  pendingQuantity[indexInOriginalArray],
                  selectedFulfillmentItem,
                  rowIndex,
                  indexInOriginalArray
                );
              }
            },
            {
              title: 'Yes',
              className: 'bg-app text-white ml-r',
              onClick: () => {
                const updatedQtyToFulfill = [...quantityToFulfill];
                setDisableQty(false);

                updatedQtyToFulfill[indexInOriginalArray] = rowData.committing;
                setQuantityToFulfill(updatedQtyToFulfill);
                updateInventory(
                  rowData.committing,
                  selectedFulfillmentItem.type,
                  availableQty,
                  pendingQuantity[indexInOriginalArray],
                  selectedFulfillmentItem,
                  rowIndex,
                  indexInOriginalArray
                );
              }
            }
          ];
          showAlert(
            'Insufficient Stock!',
            'Selected Warehouse does not have sufficient qty. Do you wish to continue?',
            buttons
          );
          return;
        }
        break;
      case 'receiving':
        updateInventoryForRG(rowData.receiving, indexInOriginalArray);
        break;
      default:
        let tempState = [...fulfillmentItems];
        tempState[indexInOriginalArray][columnKey] = rowData[columnKey];

        updatedRowData[rowIndex][columnKey] = rowData[columnKey];
        if (!Utility.isEmpty(rowData[columnKey])) {
          tempState[indexInOriginalArray].invalidFields = tempState[
            indexInOriginalArray
          ].invalidFields.filter((invF: any) => invF !== columnKey);
        }
        props.updateFulfillmentData(tempState, columnConfig);

        break;
    }
  };

  const getAvailableWarehouses = (fulfillmentItem: any) => {
    let data: any[] = [];
    if (Utility.isEmpty(fulfillmentItem)) return [];
    if (fulfillmentItem.type === PRODUCT_TYPE.NON_TRACKED) {
      props.warehouseProduct?.forEach((warehouse: any) => {
        if (warehouse.primary) {
          const item = {
            label: warehouse.name,
            value: warehouse.code
          };
          data.push(item);
        }
      });
    } else if (props.module === DOC_TYPE.GOODS_RECEIPT) {
      props.warehouseProduct?.forEach((warehouse: any) => {
        const item = {
          label: warehouse.name,
          value: warehouse.code
        };
        data.push(item);
      });
    } else {
      props.warehouseProduct?.forEach((warehouse: any) => {
        if (
          warehouse?.productAvailableQuantity?.[fulfillmentItem.productCode]
        ) {
          const item = {
            label: warehouse.name,
            value: warehouse.code
          };
          data.push(item);
        }
      });
    }
    return data;
  };
  const updateConfig = (fulfillmentItem?: any) => {
    let config = columnConfig;
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'warehouse':
          if (
            fulfillmentItem?.product?.advancedTracking ===
              TRACKING_TYPE.BATCH ||
            fulfillmentItem?.product?.advancedTracking === TRACKING_TYPE.SERIAL
          ) {
            conf.editable = false;
          } else {
            conf.editable = true;
            // multipleWarehouseData.length === 0 &&
            //   fulfillmentItem?.product?.advancedTracking ===  add this condn when adding multiple WH
            //     TRACKING_TYPE.NONE

            conf.dropdownConfig.data = getAvailableWarehouses(fulfillmentItem);
            conf.dropdownConfig.renderer = (index: any, obj: any) => {
              return obj?.label ?? '';
            };
          }
          break;
        case 'products':
          conf.renderer = ({ rowData }: any) => {
            return (
              <div className="column parent-width">
                <div className="row parent-width">
                  <DKLabel text={rowData?.products || ''} className="fs-m" />
                </div>
                <div className="row">
                  <DKLabel
                    text={rowData?.product?.documentSequenceCode || ''}
                    className="text-gray fs-s"
                  />
                </div>
              </div>
            );
          };
          break;
        default:
          break;
      }
    });
    setColumnConfig(config.filter((col: any) => !col.hidden));
  };

  const getWarehouse = (item: any, index: any) => {
    if (props.warehouseProduct && props.warehouseProduct.length > 0) {
      if (item?.warehouseInventoryData) {
        let setOfUniqueWarehouses = new Set(
          item?.warehouseInventoryData?.map((item: any) => item.warehouseCode)
        );
        let uniqueWarehouses: any = item
          ? Array.from(
              setOfUniqueWarehouses ? setOfUniqueWarehouses : []
            )?.filter((warehouse: any) => !Utility.isEmpty(warehouse))
          : [];
        if (uniqueWarehouses?.length === 1) {
          return {
            ...item?.warehouseInventoryData?.[0],
            name: item?.warehouseInventoryData?.[0]?.warehouseName
          };
        } else {
          return { name: 'Multiple Warehouse' };
        }
      } else {
        if (item.product.warehouseCode) {
        }
        let list = props.warehouseProduct.filter(
          (warehouse: any) =>
            warehouse.code ===
            (selectedWarehouse && selectedWarehouse[index]
              ? selectedWarehouse[index]
              : '')
        );
        if (list && list.length > 0) {
          return list[0];
        } else {
          return {};
        }
      }
    }
    return {};
  };

  const getNonEditableColumns = (index: any) => {
    if (
      fulfillmentItems[index].product.advancedTracking === TRACKING_TYPE.NONE
    ) {
      let availableQty = fulfillmentItems[index].documentUOMSchemaDefinition
        ? fulfillmentItems[index]?.uomAvailableQuantity
        : fulfillmentItems[index].availableQty
        ? fulfillmentItems[index].availableQty
        : fulfillmentItems[index].availableQuantity;
      const errorMessage =
        'Allocate quantities from multiple warehouses. Total available quantity is (' +
        Utility.roundingOff(availableQty, QTY_ROUNDOFF_PRECISION) +
        ')';
      const totalQuantity =
        (fulfillmentItems[index].warehouseInventoryData &&
          fulfillmentItems[index].warehouseInventoryData.length > 0 &&
          fulfillmentItems[index].warehouseInventoryData.reduce(
            (a: any, b: any) => (a += parseFloat(b['quantity'])),
            0
          )) ||
        0;
      if (
        Number(quantityToFulfill[index]) !== Number(totalQuantity) ||
        totalQuantity <= 0
      ) {
        // return false
      } else {
        return ['warehouse'];
      }
    }
    if (
      fulfillmentItems[index].product.advancedTracking === TRACKING_TYPE.SERIAL
    ) {
      if (props.module !== DOC_TYPE.GOODS_RECEIPT) {
        if (
          fulfillmentItems[index]?.isQuickCommit ||
          fulfillmentItems[index]?.advancedTrackingFulfilmentData?.length ===
            (fulfillmentItems[index].documentUOMSchemaDefinition
              ? Utility.getUomWarehouseQuantity(
                  quantityToFulfill[index],
                  fulfillmentItems[index].documentUOMSchemaDefinition
                )
              : quantityToFulfill[index])
        ) {
          return ['warehouse'];
        }
      } else {
        if (
          fulfillmentItems[index]?.advancedTrackingMetaData?.length ===
          (fulfillmentItems[index].documentUOMSchemaDefinition
            ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                quantityToFulfill[index],
                fulfillmentItems[index].documentUOMSchemaDefinition
              )
            : quantityToFulfill[index])
        ) {
          return ['warehouse'];
        }
      }
    }

    return [];
  };
  const getIsNonEditableColumns = (index: any) => {
    let setOfUniqueWarehouses = new Set(
      fulfillmentItems?.[index]?.warehouseInventoryData?.map(
        (item: any) => item.warehouseCode
      )
    );
    let uniqueWarehouses: any = fulfillmentItems
      ? Array.from(setOfUniqueWarehouses ? setOfUniqueWarehouses : [])?.filter(
          (warehouse: any) => !Utility.isEmpty(warehouse)
        )
      : [];
    if (uniqueWarehouses?.length > 1) {
      return true;
    } else {
      return false;
    }
    if (
      fulfillmentItems[index].product.advancedTracking === TRACKING_TYPE.NONE
    ) {
      let availableQty = fulfillmentItems[index].documentUOMSchemaDefinition
        ? fulfillmentItems[index]?.uomAvailableQuantity
        : fulfillmentItems[index].availableQty
        ? fulfillmentItems[index].availableQty
        : fulfillmentItems[index].availableQuantity;
      const errorMessage =
        'Allocate quantities from multiple warehouses. Total available quantity is (' +
        Utility.roundingOff(availableQty, QTY_ROUNDOFF_PRECISION) +
        ')';
      const totalQuantity =
        (fulfillmentItems[index].warehouseInventoryData &&
          fulfillmentItems[index].warehouseInventoryData.length > 0 &&
          fulfillmentItems[index].warehouseInventoryData.reduce(
            (a: any, b: any) => (a += parseFloat(b['quantity'])),
            0
          )) ||
        0;
      if (
        Number(quantityToFulfill[index]) !== Number(totalQuantity) ||
        totalQuantity <= 0
      ) {
        return false;
      } else {
        return true;
      }
    }
    if (
      fulfillmentItems[index].product.advancedTracking === TRACKING_TYPE.SERIAL
    ) {
      if (props.module !== DOC_TYPE.GOODS_RECEIPT) {
        if (
          fulfillmentItems[index]?.isQuickCommit ||
          fulfillmentItems[index]?.advancedTrackingFulfilmentData?.length ===
            (fulfillmentItems[index].documentUOMSchemaDefinition
              ? Utility.getUomWarehouseQuantity(
                  quantityToFulfill[index],
                  fulfillmentItems[index].documentUOMSchemaDefinition
                )
              : quantityToFulfill[index])
        ) {
          return true;
        }
      } else {
        if (
          fulfillmentItems[index]?.advancedTrackingMetaData?.length ===
          (fulfillmentItems[index].documentUOMSchemaDefinition
            ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                quantityToFulfill[index],
                fulfillmentItems[index].documentUOMSchemaDefinition
              )
            : quantityToFulfill[index])
        ) {
          return true;
        }
      }
    }

    return false;
  };

  const getRowData = () => {
    let rowData: any = [];
    {
      props.warehouseProduct &&
        props.warehouseProduct.length &&
        props.fulfillmentItems &&
        props.fulfillmentItems.map((item: any, index: number) => {
          let pendingQuantityForFull = Utility.getPendingQuantityForPopup(
            item,
            true
          );
          if (pendingQuantityForFull <= 0 || pendingQuantity[index] <= 0) {
            return;
          }
          if (props.module === DOC_TYPE.GOODS_RECEIPT) {
            let qty = item.documentUOMSchemaDefinition
              ? item.uomQuantityRequired ||
                Utility.uomPendingToBeUomReceivedQuantity(item)
              : item.quantityRequired ||
                Utility.pendingToBeReceivedQuantity(item);
            if (qty !== undefined && qty !== null && qty <= 0) {
              return;
            }
          }
          const { pendingQty, qtyToFulfill } = getPendingandRequiredQty(item);
          if (props.module === DOC_TYPE.GOODS_RECEIPT) {
            if (quantityToFulfill[index] === undefined) {
              let tmpQuantityToFulfill = [...quantityToFulfill];
              tmpQuantityToFulfill[index] = item.quantityRequired;
              //   setQuantityToFulfill(tmpQuantityToFulfill);
            }
            if (item.product.advancedTracking === TRACKING_TYPE.NONE) {
              if (index === 0) {
                if (Utility.isEmpty(selectedWarehouse)) {
                  setSelectedWarehouse([item.warehouseCode]);
                }
              }
              if (selectedWarehouse && selectedWarehouse[index] === undefined) {
                let tmpSelectedWarehouse = [...selectedWarehouse];
                tmpSelectedWarehouse[index] = item.warehouseCode;
                // setSelectedWarehouse(tmpSelectedWarehouse);
              }
            }
          } else {
            if (quantityToFulfill[index] === undefined) {
              let tmpQuantityToFulfill = [...quantityToFulfill];
              tmpQuantityToFulfill[index] = pendingQty;
              //   setQuantityToFulfill(tmpQuantityToFulfill);
            }
            if (index === 0) {
              if (Utility.isEmpty(selectedWarehouse)) {
                setSelectedWarehouse([item.warehouseCode]);
              }
            }
            if (selectedWarehouse && selectedWarehouse[index] === undefined) {
              let tmpSelectedWarehouse = [...selectedWarehouse];
              tmpSelectedWarehouse[index] = item.warehouseCode;
              //   setSelectedWarehouse(tmpSelectedWarehouse);
            }
          }
          item['warehouseCode'] = item?.product?.warehouseCode
            ? item?.product?.warehouseCode
            : selectedWarehouse?.[index]
            ? selectedWarehouse[index]
            : '';
          if (pendingQuantity[index] === undefined) {
            let tmpPendingQuantity = [...pendingQuantity];
            tmpPendingQuantity[index] = pendingQty;
            // setPendingQuantity(tmpPendingQuantity);
          }
          let rowObj: any = {
            ...item,
            products: item.product?.name,
            description: item.productDescription,
            uom: getSelectedUomValue(item),
            required:
              props.module === DOC_TYPE.GOODS_RECEIPT &&
              pendingQuantity[index] !== undefined &&
              pendingQuantity[index] !== null &&
              pendingQuantity[index] >= 0
                ? pendingQuantity[index]
                : item.quantityRequired !== undefined &&
                  item.quantityRequired !== null
                ? item.quantityRequired
                : pendingQty,
            committing:
              quantityToFulfill[index] >= 0
                ? quantityToFulfill[index]
                : qtyToFulfill,
            receiving:
              quantityToFulfill[index] >= 0
                ? quantityToFulfill[index]
                : pendingQty,
            advancedTracking: item.product.advancedTracking,
            warehouse:
              item.product?.advancedTracking === TRACKING_TYPE.BATCH ||
              item.product?.advancedTracking === TRACKING_TYPE.SERIAL
                ? { name: '-' }
                : getIsNonEditableColumns(index)
                ? { name: 'Multiple Warehouse' }
                : getWarehouse(item, index),
            nonEditableColumns: getNonEditableColumns(index),
            productId: item.product?.id,
            expanded: false,
            expandableView: (
              <ExpandedFulfillmentTable
                data={item}
                type={
                  item.product.advancedTracking === TRACKING_TYPE.BATCH
                    ? TRACKING_TYPE.BATCH
                    : item.product.advancedTracking === TRACKING_TYPE.SERIAL
                    ? TRACKING_TYPE.SERIAL
                    : TRACKING_TYPE.NONE
                }
              />
            )
          };

          item.customField?.forEach((cf: any) => {
            if (!(cf.id in rowObj)) {
              rowObj = { ...rowObj, [`${cf.id}`]: cf.value };
            }
          });

          rowData.push(rowObj);
        });
    }
    return rowData;
  };

  const getErrorMessage = (
    item: any,
    tooltipID: number,
    type: PRODUCT_TYPE
  ) => {
    if (selectedIndex == -1 || Utility.isEmpty(selectedFulfillmentItem)) {
      return;
    }
    let indexInOriginalArray = fulfillmentItems.findIndex(
      (item1: any) => item1.id == item.id
    );

    const committedQty = quantityToFulfill[indexInOriginalArray];
    const required = pendingQuantity[indexInOriginalArray];
    let filteredProduct = props.warehouseProduct?.find(
      (warehouse: any) =>
        warehouse.code ===
        (defaultWarehouse ? defaultWarehouse : item.warehouseCode)
    );
    let tempfulfillItem = { ...item };
    tempfulfillItem.availableProductQuantity =
      (!Utility.isEmpty(filteredProduct?.productAvailableQuantity) &&
        filteredProduct?.productAvailableQuantity[
          tempfulfillItem.productCode
        ]) ||
      tempfulfillItem.product.availableQuantity ||
      0;
    let availableQty = item.documentUOMSchemaDefinition
      ? item?.uomAvailableQuantity
      : Utility.getAvailableQuantity(tempfulfillItem);
    let errorMessage = `${tempfulfillItem?.product?.name} quantity cannot be more than the required quantity`;
    let hasError = false;
    let activeToolTip;
    let shortBy = Math.abs(availableQty - committedQty);
    if (type === PRODUCT_TYPE.NON_TRACKED) {
      return;
    }
    if (
      item?.warehouseInventoryData?.length === 1 &&
      props.module === DOC_TYPE.FULFILLMENT
    ) {
      if (
        parseFloat(committedQty) > availableQty &&
        quantityToFulfill[indexInOriginalArray] > 0
      ) {
        errorMessage = `Selected warehouse for ${tempfulfillItem?.product?.name} has insufficient stock. If you proceed ahead with it, the Stock level will become negative for the selected warehouse and the Journal Entry passed for the same may reflect the value 0 (for Cost of Goods Sold account) for the stock that is in insufficient qty`;
        hasError = true;
      }
    }

    if (
      parseFloat(committedQty) > required &&
      quantityToFulfill[indexInOriginalArray] > 0 &&
      !Utility.isSellsToleranceSettingsEnabled(
        props.parentDocumentType,
        props.parentDocumentDetails
      ) &&
      props.parentDocumentType &&
      props.parentDocumentType !== DOC_TYPE.JOB_WORK_OUT_ORDER
    ) {
      errorMessage = `${tempfulfillItem?.product?.name} quantity cannot be more than the required quantity (${required})`;
      hasError = true;
    } else if (
      props.parentDocumentType &&
      props.parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      !Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
      parseFloat(committedQty) > required &&
      quantityToFulfill[indexInOriginalArray] > 0 &&
      !Utility.isSellsToleranceSettingsEnabled(
        props.parentDocumentType,
        props.parentDocumentDetails
      )
      // add condition for not more qty than dispatch qty///////////////
    ) {
      errorMessage = `${tempfulfillItem?.product?.name} quantity cannot be more than the required quantity (${required})`;
      hasError = true;
    }

    if (
      Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
      props.parentDocumentType &&
      props.parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      parseFloat(committedQty) > Number(item.productQtyForWhichRmDispatched) //totalFGDispatchedQty
    ) {
      errorMessage = `${tempfulfillItem?.product?.name} quantity cannot be more than the dispatched quantity (${item.productQtyForWhichRmDispatched})`; //totalFGDispatchedQty
      hasError = true;
    }

    if (
      !Utility.isJWOReceiveDispatchAdditionalQtyEnable() &&
      props.parentDocumentType &&
      props.parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      Number(item.receivedQuantityInOrder) !== 0 &&
      parseFloat(committedQty) >
        Number(item.productQtyForWhichRmDispatched) -
          Number(item.receivedQuantityInOrder) //totalFGDispatchedQty
    ) {
      errorMessage = `${tempfulfillItem?.product?.name} Dispatched Quantity not enough to receive entered goods (${item.productQtyForWhichRmDispatched}) and already received quantity is (${item.receivedQuantityInOrder})`; //totalFGDispatchedQty
      hasError = true;
    }
    return (
      <>
        {hasError && (
          <div className="row rounded bg-red-100 p-2 parent-width mt-m mb-s">
            <span>{errorMessage}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      {getErrorMessage(
        selectedFulfillmentItem,
        selectedFulfillmentItem.lineNumber,
        selectedFulfillmentItem.type
      )}
      {getDataGrid()}
      {showBatchTracking && props.module === DOC_TYPE.GOODS_RECEIPT && (
        <BatchTrackingReceive
          isMrpFlow={false}
          itemDetails={{
            ...fulfillmentItems[selectedIndex],
            requiredQuantity: quantityToFulfill[selectedIndex]
          }}
          docType={props.docType}
          module={MODULE_TYPE.BUY}
          defaultProductWarehouse={defaultWarehouse}
          onBatchSave={(
            data: any,
            quantity: any,
            rawMaterialToConsume?: any
          ) => {
            const receivedQuantity = Utility.roundingOff(
              fulfillmentItems[selectedIndex].documentUOMSchemaDefinition
                ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                    parseFloat(quantity),
                    fulfillmentItems[selectedIndex].documentUOMSchemaDefinition
                  )
                : quantity
            );
            quantity = Utility.roundingOff(quantity, QTY_ROUNDOFF_PRECISION);
            const updatedQtyToFulfill = [...quantityToFulfill];
            updatedQtyToFulfill[selectedIndex] = quantity;
            setQuantityToFulfill(updatedQtyToFulfill);
            setShowBatchTracking(false);
            // setreceivedGoodsItem({
            //   ...receivedGoodsItem,
            //   advancedTracking: TRACKING_TYPE.BATCH,
            //   advancedTrackingMetaData: data,
            //   receiptQuantity: receivedQuantity
            // });
            let inventory = {
              ...fulfillmentItems[selectedIndex],
              advancedTracking: TRACKING_TYPE.BATCH,
              advancedTrackingType: TRACKING_TYPE.BATCH,
              advancedTrackingMetaData: data,
              receiptQuantity: receivedQuantity,
              rawMaterialToConsume: rawMaterialToConsume || []
            };
            if (fulfillmentItems[selectedIndex].documentUOMSchemaDefinition) {
              inventory.uomReceiptQuantity = quantity;
            }
            // setreceivedGoodsItem(inventory);
            let updatedState = [...fulfillmentItems];
            updatedState[selectedIndex] = inventory;
            setSelectedFulfillmentItem(inventory);
            setFulfillmentItems(updatedState);
          }}
          onClose={() => {
            setShowBatchTracking(false);
          }}
          parentDocumentType={props.parentDocumentType ?? ''}
          parentDocumentDetails={props.parentDocumentDetails}
        ></BatchTrackingReceive>
      )}

      {showBatchTracking &&
        props.module === DOC_TYPE.FULFILLMENT &&
        selectedIndex !== -1 && (
          <BatchTrackingAssignment
            needOnlyGrid={false}
            allotedAdvancedTrackingData={
              allotedAdvancedTrackingData || [] // recheck
            }
            isMrpFlow={false}
            itemDetails={{
              ...fulfillmentItems[selectedIndex],
              requiredQuantity: pendingQuantity[selectedIndex]
            }}
            batchMultipleWarehouseData={batchMultipleWarehouseData}
            module={MODULE_TYPE.SELL}
            defaultProductWarehouse={defaultWarehouse}
            onBatchSave={(data: any, qtyToFulfill: any) => {
              const updatedQtyToFulfill = [...quantityToFulfill];
              updatedQtyToFulfill[selectedIndex] = qtyToFulfill;
              setQuantityToFulfill(updatedQtyToFulfill); //

              const fulfilledQuantity = fulfillmentItems[selectedIndex]
                .documentUOMSchemaDefinition
                ? Utility.roundingOff(
                    Utility.getUomWarehouseQuantityWithoutRoundOff(
                      parseFloat(qtyToFulfill),
                      fulfillmentItems[selectedIndex]
                        .documentUOMSchemaDefinition
                    ),
                    QTY_ROUNDOFF_PRECISION
                  )
                : qtyToFulfill;
              setShowBatchTracking(false);
              let inventory = {
                ...fulfillmentItems[selectedIndex],
                advancedTracking: TRACKING_TYPE.BATCH,
                advancedTrackingFulfilmentData: data,
                isQuickCommit: false,
                quantityFulfilled: fulfilledQuantity,
                fulfilledQuantity: fulfilledQuantity
              };
              if (fulfillmentItems[selectedIndex].documentUOMSchemaDefinition) {
                inventory.uomFulfilledQuantity = qtyToFulfill;
                //inventory.uomQuantityFulfilled = quantityToFulfill;
              }
              let updatedState = [...fulfillmentItems];
              updatedState[selectedIndex] = inventory;
              setFulfillmentItems(updatedState);
              setSelectedFulfillmentItem(inventory);
              setBatchMultipleWarehouseData((prevData: any) => {
                // Filter out existing data with the same id
                const filteredData = prevData.filter(
                  (item: any) =>
                    !data.some((newItem: any) => newItem.id === item.id)
                );

                // Add the new data
                return [...filteredData, ...data];
              });
              // setExpanded(true);
            }}
            onClose={() => {
              setShowBatchTracking(false);
            }}
            parentDocumentType={props.parentDocumentType ?? ''}
            parentDocumentDetails={props.parentDocumentDetails}
          ></BatchTrackingAssignment>
        )}
      {showSerialTracking &&
        props.module !== DOC_TYPE.GOODS_RECEIPT &&
        selectedIndex !== -1 && (
          <SerialTrackedAssignment
            isMRP={false}
            itemDetails={{
              ...fulfillmentItems[selectedIndex],
              // ...fulfillmentItem,
              productQuantity: pendingQuantity[selectedIndex],
              requiredQuantity: pendingQuantity[selectedIndex]
            }}
            serialMultipleWarehouseData={serialMultipleWarehouseData}
            module={MODULE_TYPE.SELL}
            defaultProductWarehouse={defaultWarehouse}
            onSerialSave={(
              data: any,
              isQuickCommit: boolean,
              quantityToFulfills: any
            ) => {
              // setExpanded(true);
              let inventory: any = {};
              const fulfilledQuantity = fulfillmentItems[selectedIndex]
                .documentUOMSchemaDefinition
                ? Utility.getUomQuantity(
                    parseFloat(quantityToFulfills),
                    fulfillmentItems[selectedIndex].documentUOMSchemaDefinition
                  )
                : quantityToFulfills;
              const updatedQtyToFulfill = [...quantityToFulfill];
              updatedQtyToFulfill[selectedIndex] = fulfilledQuantity;

              setQuantityToFulfill(updatedQtyToFulfill);
              if (!isQuickCommit) {
                setShowSerialTracking(false);
                inventory = {
                  ...fulfillmentItems[selectedIndex],
                  advancedTrackingFulfilmentData: data,
                  advancedTracking: TRACKING_TYPE.SERIAL,
                  quantityFulfilled: quantityToFulfills,
                  fulfilledQuantity: quantityToFulfills,
                  isQuickCommit: isQuickCommit
                };
              } else {
                setShowSerialTracking(false);
                inventory = {
                  ...fulfillmentItems[selectedIndex],
                  advancedTracking: TRACKING_TYPE.NONE,
                  isQuickCommit: isQuickCommit,
                  quantityFulfilled: quantityToFulfills,
                  fulfilledQuantity: quantityToFulfills
                };
              }
              if (fulfillmentItems[selectedIndex].documentUOMSchemaDefinition) {
                inventory.uomFulfilledQuantity = fulfilledQuantity;
              }
              let updatedState = [...fulfillmentItems];
              updatedState[selectedIndex] = inventory;
              setFulfillmentItems(updatedState);
              setSelectedFulfillmentItem(inventory);
              setSerialMultipleWarehouseData((prevData: any) => {
                // Filter out existing data with the same id
                const filteredData = prevData.filter(
                  (item: any) =>
                    !data.some((newItem: any) => newItem.id === item.id)
                );

                // Add the new data
                return [...filteredData, ...data];
              });
              // setFulfillmentItems(inventory);
            }}
            onClose={() => {
              setShowSerialTracking(false);
            }}
            parentDocumentType={props.parentDocumentType ?? ''}
            parentDocumentDetails={props.parentDocumentDetails}
          ></SerialTrackedAssignment>
        )}
      {showMultipleWarehouse && props.module !== DOC_TYPE.GOODS_RECEIPT && (
        <NoneTrackedAssignment
          details={{
            ...selectedFulfillmentItem,
            quantityFulfilled: pendingQuantity[selectedIndex], // check @steven
            parentQuantityToFulfill: pendingQuantity[selectedIndex], //check @steven
            multipleWarehouseData: multipleWarehouseData
            // parentQuantityToFulfill: localQuantityToFulfill //check @steven
          }}
          module={MODULE_TYPE.SELL}
          onSave={(data: any) => {
            // setExpanded(true);
            if (data && data.length > 0) {
              const updatedQuantity = Utility.roundingOff(
                data?.reduce(function (acc: any, val: any) {
                  return acc + parseFloat(val.quantity);
                }, 0),
                QTY_ROUNDOFF_PRECISION
              );
              const fulfilledQuantity =
                selectedFulfillmentItem.documentUOMSchemaDefinition
                  ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                      parseFloat(updatedQuantity.toString()),
                      selectedFulfillmentItem.documentUOMSchemaDefinition
                    )
                  : updatedQuantity;
              let inventory = {
                ...selectedFulfillmentItem,
                quantityFulfilled: parseFloat(fulfilledQuantity),
                fulfilledQuantity: parseFloat(fulfilledQuantity),
                warehouseInventoryData: data
              };
              if (selectedFulfillmentItem.documentUOMSchemaDefinition) {
                inventory.uomFulfilledQuantity = updatedQuantity;
              }
              const updatedQtyToFulfill = [...quantityToFulfill];
              updatedQtyToFulfill[selectedIndex] = updatedQuantity;
              let updatedState = [...fulfillmentItems];
              updatedState[selectedIndex] = inventory;
              setQuantityToFulfill(updatedQtyToFulfill); // check @steven
              setFulfillmentItems(updatedState);
              setSelectedFulfillmentItem(inventory);
              setMultipleWarehouseData((prevData: any) => {
                // Filter out existing data with the same id
                const filteredData = prevData.filter(
                  (item: any) =>
                    !data.some((newItem: any) => newItem.id === item.id)
                );

                // Add the new data
                return [...filteredData, ...data];
              });
              setShowMultipleWarehouse(false);
            }
          }}
          onCancel={() => {
            setShowMultipleWarehouse(false);
          }}
          parentDocumentType={props.parentDocumentType ?? ''}
          parentDocumentDetails={props.parentDocumentDetails}
        />
      )}
      {showMultipleWarehouse && props.module === DOC_TYPE.GOODS_RECEIPT && (
        <NoneTrackedReceive
          details={{
            ...selectedFulfillmentItem,
            quantityFulfilled: pendingQuantity[selectedIndex],
            parentQuantityToFulfill: pendingQuantity[selectedIndex]
          }}
          defaultProductWarehouse={defaultWarehouse}
          onSave={(data: any, rawMaterialToConsume?: any) => {
            if (data && data.length > 0) {
              const updatedQuantity = Utility.roundingOff(
                data?.reduce(function (acc: any, val: any) {
                  return acc + parseFloat(val.quantity);
                }, 0),
                QTY_ROUNDOFF_PRECISION
              );
              const fulfilledQuantity = Utility.roundingOff(
                selectedFulfillmentItem.documentUOMSchemaDefinition
                  ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                      parseFloat(updatedQuantity.toString()),
                      selectedFulfillmentItem.documentUOMSchemaDefinition
                    )
                  : updatedQuantity,
                QTY_ROUNDOFF_PRECISION
              );
              let inventory = {
                ...selectedFulfillmentItem,
                quantityFulfilled: fulfilledQuantity,
                fulfilledQuantity: fulfilledQuantity,
                warehouseInventoryData: data,
                receiptQuantity: fulfilledQuantity,
                rawMaterialToConsume: rawMaterialToConsume || []
              };
              if (selectedFulfillmentItem.documentUOMSchemaDefinition) {
                inventory.uomReceiptQuantity = updatedQuantity;
              }
              const updatedQtyToFulfill = [...quantityToFulfill];
              updatedQtyToFulfill[selectedIndex] = updatedQuantity;
              let updatedState = [...fulfillmentItems];
              updatedState[selectedIndex] = inventory;
              setQuantityToFulfill(updatedQtyToFulfill);
              setSelectedFulfillmentItem(inventory);
              setFulfillmentItems(updatedState);
              setMultipleWarehouseData(data);
              setShowMultipleWarehouse(false);
            }
          }}
          onCancel={() => {
            setShowMultipleWarehouse(false);
          }}
          parentDocumentType={props.parentDocumentType ?? ''}
          parentDocumentDetails={props.parentDocumentDetails}
        />
      )}
      {showSerialTracking && props.module === DOC_TYPE.GOODS_RECEIPT && (
        <SerialTrackedReceive
          isMRP={false}
          itemDetails={{
            ...fulfillmentItems[selectedIndex],
            requiredQuantity: quantityToFulfill[selectedIndex]
          }}
          module={MODULE_TYPE.BUY}
          defaultProductWarehouse={defaultWarehouse}
          onSerialSave={(
            data: any,
            isQuickCommit: boolean,
            quantity: any,
            rawMaterialToConsume?: any
          ) => {
            const receivedQuantity = fulfillmentItems[selectedIndex]
              .documentUOMSchemaDefinition
              ? Utility.getUomQuantity(
                  parseFloat(quantity),
                  fulfillmentItems[selectedIndex].documentUOMSchemaDefinition
                )
              : quantity;
            const updatedQtyToFulfill = [...quantityToFulfill];
            updatedQtyToFulfill[selectedIndex] = receivedQuantity;

            setQuantityToFulfill(updatedQtyToFulfill);
            // setQuantityToReceived(receivedQuantity);
            let inventory = {
              ...fulfillmentItems[selectedIndex],
              advancedTracking: TRACKING_TYPE.SERIAL,
              advancedTrackingType: TRACKING_TYPE.SERIAL,
              advancedTrackingMetaData: data,
              receiptQuantity: receivedQuantity,
              rawMaterialToConsume: rawMaterialToConsume || []
            };
            if (fulfillmentItems[selectedIndex].documentUOMSchemaDefinition) {
              inventory.uomReceiptQuantity = receivedQuantity;
            }
            let updatedState = [...fulfillmentItems];
            updatedState[selectedIndex] = inventory;
            setFulfillmentItems(updatedState);
            setSelectedFulfillmentItem(inventory);

            // setreceivedGoodsItem({
            //   ...receivedGoodsItem,
            //   advancedTracking: TRACKING_TYPE.SERIAL,
            //   advancedTrackingMetaData: data,
            //   receiptQuantity: quantity
            // });
            setShowSerialTracking(false);
          }}
          onClose={() => {
            setShowSerialTracking(false);
          }}
          parentDocumentType={props.parentDocumentType ?? ''}
          parentDocumentDetails={props.parentDocumentDetails}
        ></SerialTrackedReceive>
      )}
    </div>
  );
};

export default FulfillmentGrid;
